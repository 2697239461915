import {atom} from "recoil";
import { v1 } from "uuid";
// import axios from "axios";

export const locationAtom = atom({
  key: "location",
  default: "home"
});

export const isDialogOpenAtom = atom({
  key: "isDialogOpen",
  default: false
});

export const isVideoDialogOpenAtom = atom({
  key: `isVideoDialogOpen/${v1()}`,
  default: {
    isOpen: false,
    url: "#"
  }
});

export const defaultExpendedIdAtom = atom({
  key: "defaultExpendedId",
  default: ""
});

export const defaultExpendedCategoryAtom = atom({
  key: "defaultExpendedCategory",
  default: ""
});

export const isMiniNavOpenAtom = atom({
  key: "isMiniNavOpen",
  default: false
});

export const boardSummaryAtom = atom({
  key: `boardTitle/${v1()}`,
  default: [
    {
      boardTitle: "공지 사항 +",
      icon: "ico-notice.svg",
      linkTo: "/notice",
      contents: [
        {
          id: "notice5",
          category: "모집공고",
          title: "신한DS 금융SW 아카데미 2023년 3, 4차수(2기) 교육생 모집 안내",
          paragraph:
            <p>
              안녕하세요. <br /><br />

              신한DS 금융SW 아카데미 2023년 3, 4차수(2기)  지원서 접수가 `23.07.03 부터 `23.07.28까지  진행됩니다. <br />
              신청 접수는 서울기술교육센터 온라인 접수센터에서만 가능하며, <br />
              접수 기간 외 신청은 받지 않으므로 기한(시간) 엄수 바랍니다. <br /><br />

              신한DS 금융SW 아카데미 풀스택 개발자 양성과정 교육생 모집에 관심을 가져주셔서 진심으로 감사드립니다. <br />
            </p>,
          image: "notice_05_img.jpg",
        },
        {
          id: "notice4",
          category: "주요소식",
          title: "신한DS 금융SW 아카데미 1기 개강",
          paragraph:
            <p>
              지난 2월 21일 신한DS 금융SW 아카데미 1기(2023년 1,2차수) 합격자를 대상으로 개강식이 진행되었습니다. <br/><br />

              개강식에서는 앞으로 함께하게 될 동료들과 인사를 나누고 <br />
              본 과정을 운영하는 기관 소개, 교육 과정 및 프로젝트 설명, 강사 소개 등이 진행되었으며 <br />
              신한DS 경영전략 본부장, 서울기술교육센터 센터장님께서 훈련과정 이수 후 교육생 취업 성공을 위한 축사를 해주셨습니다. <br /><br />

              이번 1기를 시작으로 지속적으로 교육생을 모집할 예정이오니 많은 관심 부탁드립니다. <br />
            </p>,
          image: "notice_04_img.jpg",
        },
        {
          id: "notice3",
          category: "모집공고",
          title: "신한DS 금융SW 아카데미 2023년 1,2차수(1기) 최종합격 발표 안내",
          paragraph:
          <p>
            안녕하세요. <br /><br />

            면접 참여 대상자 중 합격 및 불합격자 전원에게 2월 8일(수) 오전 10시 이후 문자로 개별 연락 예정입니다. <br /><br />

            신한DS 금융SW 아카데미 모집에 관심을 가져 주셔서 진심으로 감사드립니다. <br />
          </p>,
          image: "none"
        },
        {
          id: "notice2",
          category: "모집공고",
          title: "신한DS 금융SW 아카데미 2023년 1,2차수 교육생 모집 안내",
          paragraph:
          <p>
            안녕하세요. <br /><br />

            신한DS 금융SW 아카데미 2023년 1, 2차수 서류 전형 지원서 접수가 `23.01.01 부터 `23.01.31까지  진행됩니다. <br />
            신청 접수는 서울기술교육센터 온라인 접수센터에서만 가능하며, <br />
            접수 기간 외 신청은 받지 않으므로 기한(시간) 엄수 바랍니다. <br /><br />

            상세 전형 일정은 추후 공지 예정이며, 전형별 진행 일정은 합격자에게  개별 연락 예정입니다. <br /><br />

            신한DS 금융SW 아카데미 풀스택 개발자 양성과정 교육생 모집에 관심을 가져주셔서 진심으로 감사드립니다.  <br />

          </p>,
          image:"notice_02_img.jpg"
        },
        {
          id: "notice1",
          category: "주요소식",
          title: "신한DS, 'K-Digital Training 디지털선도기업 아카데미' 사업 선정",
          paragraph:
          <p>
            신한금융그룹 신한DS(대표 조경선)가  고용노동부와 직업능력심사평가원이 진행하는 2022년 K-디지털 트레이닝 <br />
            ‘디지털 선도기업 아카데미’ 추가심사 에서 사업자로 선정되었습니다. <br />
            &lt; 직업능력심사평가원(정책) 제2022- 122호 &gt; K-디지털 트레이닝 ‘디지털 선도기업 아카데미’ 추가심사 선정결과 공고 참조 <br />
          </p>,
          image: "none"
        },
      ]
    },
    {
      boardTitle: "FAQ +",
      icon: "ico-faq.svg",
      linkTo: "/faq",
      contents: [
        {
          id: "faq17",
          category: "수강신청",
          title: "수강 신청은 어떻게 하나요?",
          answer:
          <p>
            ㅇ 신청 접수는 대한상공회의소 서울기술교육센터 온라인접수 센터에서만 가능합니다. <br /><br />

            ㅇ 본 과정은 별도 절차에 따라 훈련생이 선발, 운영되오니 홈페이지 모집안내 페이지를 참고해 주세요. <br />
          </p>
        },
        {
          id: "faq16",
          category: "수강신청",
          title: "국민내일배움카드만 있으면 수강이 가능한 건가요?",
          answer:
          <p>
            ㅇ 아래 사항에 해당할 경우 수강이 불가 합니다 <br />
            - 카드 잔액이 0원일 경우 <br />
            - KDT 수강 이력이 있는 경우 수강신청은 가능하나 자기 부담금 발생 안내 <br />
          </p>
        },
        {
          id: "faq15",
          category: "수강신청",
          title: "K-Digital Trainning 수강 이력이 있는지는 어떻게 확인하나요?",
          answer:
          <p>
            ㅇ HRD-Net 로그인 &gt; 나의정보 &gt; 나의훈련 &gt; 직업훈련이력 <br />
            - 조회된 훈련과정명을 HRD-Net 검색창에 검색 시 훈련과정명 밑에 <br />
              'K-디지털트레이닝' 혹은 '4차산업혁명인력양성' 표시가 있으면 KDT 과정에 해당 <br /><br />

              * 조회가 되지 않는 과정일 경우 훈련 받은 기관에 문의 바랍니다. <br />
          </p>
        },
        {
          id: "faq14",
          category: "수강신청",
          title: "기존에 참여하고 있던 KDT 교육 프로그램 하차 후 새로운 KDT 과정 신청이 가능한가요?",
          answer:
          <p>
            ㅇ 확정자 신고기간 내 그만둘 경우 KDT 0회 참여로 간주되어 재참여가 가능합니다. <br /><br />

            ㅇ  확정자 신고기간이 지난 경우라면 남은 훈련기간에 상관없이 KDT 1회 참여로 간주되어 <br />
                수강시 훈련생 자기부담금이 발생합니다. <br />
          </p>
        },
        {
          id: "faq13",
          category: "수강신청",
          title: "여러 개 과정을 동시에 수강할 수 있나요?",
          answer:
          <p>
            ㅇ 국민내일배움카드를 이용하는 과정의 경우 중앙행정기관 또는 지방자치단체로부터 훈련비를 지원받는 <br />
                훈련(또는 사업)에 중복 참여 불가합니다. <br /><br />

            ㅇ 일반적으로 국비지원 교육은 훈련기간이 겹칠 경우 중복수강이 어려우나 훈련유형(온라인, 집체)에 따라 <br />
                다를 수 있으니 고용센터에 문의 해주세요. <br />
          </p>
        },
        {
          id: "faq12",
          category: "국민내일배움카드",
          title: "꼭 국민내일배움카드가 있어야 하나요?",
          answer:
          <p>
            ㅇ K-Digital Trainning 사업은 국민내일배움카드로 훈련비 및 훈련장려금을 지원받는 과정입니다. <br /><br />

              국민내일배움카드가 없으면 수강이 불가 합니다. <br />
          </p>
        },
        {
          id: "faq11",
          category: "국민내일배움카드",
          title: "국민내일배움카드는 어떻게 발급받나요?",
          answer:
          <p>
            ㅇ 거주지 관할 고용센터 방문 or HRD-Net 접속 → 필요서류 제출 및 카드 발급 신청 → 카드 발급 완료(최대 7일 소요) <br />

                자세한 사항은 직업훈련포탈 (https://www.hrd.go.kr) 국민내일배움카드 발급 안내 페이지 참고 <br /><br />


            ㅇ 카드 발급은 가급적 신청 카드사의 은행지점을 직접 방문하여 수령 <br />

                (국민내일배움카드 신청 시 카드 수령방법을 “은행 방문”으로 선택) <br />

                * 우편 신청 시 7일 이상 소요되어 수강 신청 일정에 차질 우려가 있습니다. <br />
          </p>
        },
        {
          id: "faq10",
          category: "국민내일배움카드",
          title: "카드 신청 후 수령까지 기간은 얼마나 걸리나요?",
          answer:
          <p>
            ㅇ 지정 은행(신한/농협)을 방문하여 카드를 직접 수령하는 경우는 최대 7일 정도 소요되며, <br />

                  카드사를 통해 우편배송을 신청할 경우 7일에서 최대 14일까지 소요됩니다. <br />
          </p>
        },
        {
          id: "faq9",
          category: "국민내일배움카드",
          title: "카드 발급에 연령 제한도 있나요?",
          answer:
          <p>
            ㅇ 국민내일배움카드는 만 75세 미만만 국민 누구나 발급받을 수 있습니다. <br />

                다만, 아래에 해당할 경우 제외됩니다. <br />

            - 현직 공무원, 사립학교 교직원 <br />

            - 만 75세 이상인 사람 <br />

            - 연 매출 1억 5천만 원 이상의 자영업자 <br />

            - 최근 1년간 월평균 소득 300만원 이상인 법인 및 단체 대표자 <br />

            - 월 임금 300만 원 이상인 특수형태근로종사자 <br />

            - 월 임금 300만 원 이상이면서 45세 미만인 대규모 기업종사자 등 <br />

            - 졸업까지 남은 수업 연한이 2년을 초과하는 대학생 <br />

                  * 졸업까지 남은 수업 연한이 2년 이내 대학(원)생은 발급 가능 <br />

            - 기타 고용노동부 고시 국민내일배움카드 운영규정에 따른 제외 인원 <br />
          </p>
        },
        {
          id: "faq8",
          category: "국민내일배움카드",
          title: "카드 발급신청 시 워크넷에 꼭 구직등록을 해야 하나요?",
          answer:
          <p>
            ㅇ 실업자 유형으로 국민내일배움카드 발급신청 시 필수 사항 입니다. <br />

            발급 절차상의 하나로 추후 이행 의무는 없으므로 등록해 주시면 됩니다. <br />
          </p>
        },
        {
          id: "faq7",
          category: "국민내일배움카드",
          title: "재직자일 때 카드를 발급받았고 현재는 퇴직한 상태입니다. 수강에 문제 는 없나요?",
          answer:
          <p>
            ㅇ 거주지 관할 고용센터에 연락 후 고용형태 변경 요청 (재직자 → 실업자) 을 해주셔야 합니다. <br/>

            미변경 시 훈련수강은 가능하나 훈련장려금은 미지급됩니다. <br/>

            * 거주지 관할 고용센터는 고용복지플러스센터(www.workplus.go.kr)에서 확인 가능 <br/>
          </p>
        },
        {
          id: "faq6",
          category: "국민내일배움카드",
          title: "카드 유효기간이 곧 만료되는데 수강에 문제가 없나요?",
          answer:
          <p>
            ㅇ 훈련시작일이 유효기간 만료 前일 경우 가능가능하며 <br />

            유효기간 만료 後일 경우에는 재발급(신규발급) 후 가능합니다. <br />
          </p>
        },
        {
          id: "faq5",
          category: "국민내일배움카드",
          title: "카드 잔액으로는 해당 과정의 수강료를 다 지불하지 못할 것 같습니다. 다른 방법이 있을까요?",
          answer:
          <p>
            ㅇ K-Digital Trainning 에 최초 참여하는 경우, 카드 잔액과 상관없이 훈련비 전액 지원이 가능합니다. <br /><br />


            ㅇ 2회 이상  참여부터는 자기 부담 금액이 발생 됩니다. (자기부담금= 수강료 - 카드잔액) <br />

                  * 자기 부담금은 훈련시작 전 카드결제 진행 (분납 불가, 계좌에 자기부담금 전액입금) <br />
          </p>
        },
        {
          id: "faq4",
          category: "국민내일배움카드",
          title: "카드를 분실한 경우 어떻게 해야 하나요?",
          answer:
          <p>
            ㅇ 이전에 발급받은 발행처(은행)에서 재발급이 가능합니다. <br />
          </p>
        },
        {
          id: "faq3",
          category: "훈련장려금",
          title: "훈련장려금은 무엇인가요?",
          answer:
          <p>
            ㅇ 성실히 훈련에 참여하는 훈련생을 대상으로 정부에서 지급하는 장려금입니다. <br /> <br />

            ㅇ  아래 조건을 만족하면서 단위기간의 80% 이상을 수강하는 경우 출석일수 만큼 지원 <br />
                  - 국민내일배움카드 발급 대상자 중 140시간 이상의 국비교육을 받는 훈련생 <br />
                  - 국민취업지원제도 1유형 및 2유형 중 특정계층에 대해 공통 지급 <br />
                      * 국민취업지원제도 지원금과 중복 수급 가능 <br /><br />

            ㅇ 지급 제외 대상 <br />
                  - 근로자, 특수형태근로자 : 실업자로 변경 시 지급 가능 <br />
                  - 자영업자 : 고용보험 임의가입의 경우 지급 가능 <br />
                  - 구직(실업)급여 지급 대상자 : 훈련참여일과 중복기간 제외 <br />
                  - 정부지원 구직활동 수당 대상자 : 고용센터에 중복 수급 가능여부 확인 <br />
                  - 타부처 및 지자체 재정지원일자리 사업 참여 및 수료자 : 고용센터에 중복 수급 가능 여부 확인 <br />
          </p>
        },
        {
          id: "faq2",
          category: "훈련장려금",
          title: "훈련장려금은 언제 받을 수 있나요?",
          answer:
          <p>
            ㅇ 매 단위기간 종료 후 1개월 이내로 수령 가능합니다. <br />
                - 각 고용센터별 상황에 따라 지급일은 유동적임 (훈련생 근로사실 확인, 등록계좌 오류, 예산 부족 등) <br />
          </p>
        },
        {
          id: "faq1",
          category: "훈련장려금",
          title: "국민내일배움카드 계좌로 훈련장려금이 들어오나요?",
          answer:
          <p>
            ㅇ 국민내일배움카드 계좌와 훈련장려금 지급 계좌는 별도로 운영됩니다. <br />
                - HRD-Net에서 수강신청 시 훈련장려금 입금 계좌 별도 입력 <br />
                    * 인터넷 은행(카카오, 토스, 케이뱅크 등), 휴대폰 계좌번호는 불가 <br />
          </p>
        },
      ]
    },
  ],

});



export const trainingCourseDatasAtom = atom({
  key: `trainingCourseData/${v1()}`,
  default: [
    {
      id: "trainingCourse01",
      title: "1기 풀스택 개발자 양성과정",
      period: "2023.02.21 ~ 2023.08.18",
      applicationPeriod: "2023.01.01 ~ 2023.01.31",
      status: "close",
      icon: "ico-course_01.svg"
    },
    {
      id: "trainingCourse02",
      title: "2기 풀스택 개발자 양성과정",
      period: "2023.08.29 ~ 2024.02.29",
      applicationPeriod: "2023.07.03 ~ 2023.07.28",
      status: "open",
      icon: "ico-course_02.svg"
    },
    {
      id: "trainingCourse03",
      title: "3기 풀스택 개발자 양성과정",
      period: "2024. 미정",
      applicationPeriod: "2024. 미정",
      status: "close",
      icon: "ico-course_03.svg"
    },
  ]
});

export const videoMsgDatasAtom = atom({
  key: `videoMsgDatas/${v1()}`,
  default: [
    {
      id: "videoMsg01",
      title: "신한DS 회사 소개",
      thumbnailUrl: "videoMsg_01.jpg",
      videoUrl: "VDASN3NFAJ0",
      tags: ["기업문화", "회사소개", "소식"]
    },
    {
      id: "videoMsg02",
      title: "신한DS 사용설명서",
      thumbnailUrl: "videoMsg_02.jpg",
      videoUrl: "JoNzMvUsqtg",
      tags: ["회사소개", "소식"]
    },
    {
      id: "videoMsg04",
      title: "신한DS 탐방기",
      thumbnailUrl: "videoMsg_04.jpg",
      videoUrl: "ZEyemoJGeAo",
      tags: ["기업문화", "회사소개", "소식"]
    },
    {
      id: "videoMsg05",
      title: "셀프 리더십 소개",
      thumbnailUrl: "videoMsg_05.jpg",
      videoUrl: "g1yFvNHGiqA",
      tags: ["취업", "리더십", "프로젝트"]
    },
    {
      id: "videoMsg06",
      title: "신한WAY 실천히어로 안내영상",
      thumbnailUrl: "videoMsg_06.jpg",
      videoUrl: "nxNuw2fOuF0",
      tags: ["교육", "기업문화", "리더십"]
    },
  ]
});

export const academyDatasAtom = atom({
  key: `academyDatas/${v1()}`,
  default: [
    {
      id: "videoMsg03",
      title: "금융SW아카데미",
      thumbnailUrl: "videoMsg_03.jpg",
      videoUrl: "-drezJmGpCU",
      tags: ["취업", "취업준비", "교육", "IT"]
    },
  ]
});
