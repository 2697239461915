import {  useLayoutEffect, useRef } from "react";
import { AOS_DURATION } from "../../common/hleper";
import MiniNavOneDeps from "../miniNav/MiniNavOneDeps";
import MiniNavTwoDeps from "../miniNav/MiniNavTwoDeps";
import "./pageThumbnail.scss";
import { Parser } from "html-to-react";


function PageThumbnail ({ imgUrl, description, miniNavProps}) {
  const imgRef = useRef();

  //const createMarkup = htmlString => ({ __html: htmlString });
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0);


      // console.log("imgUrl:", imgUrl);
      // console.log("current:", imgRef.current);

      const oneShotAnimation = () => {
        imgRef.current && imgRef.current.classList.add("playZoomIn");
      };

      setTimeout(oneShotAnimation, 1000);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <section className="thumbnailSection">
      <div className="thumbnailSection__imgWrap">
        <img ref={imgRef} src={imgUrl} alt=""/>
        <div className="thumbnailSection__imgWrap--titleWrap">
          <h3
            data-aos="fade-up"
            data-aos-delay="1000"
            data-aos-duration={AOS_DURATION}
            data-aos-once="true"
          >
            {Parser().parse(description.toString())}
          </h3>
        </div>
      </div>
      <div className="thumbnailSection__miniNavWrap">
        <MiniNavOneDeps parentMenu={miniNavProps.parentMenu} />
        <MiniNavTwoDeps childMenu={miniNavProps.childMenu} />
      </div>
    </section>
  )
}

export default PageThumbnail;