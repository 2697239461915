import "./blog.scss";

import React, {useCallback, useEffect, useState} from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import {useRecoilValueLoadable} from "recoil";
import {blogSelector, pageContentSelector} from "../../store/Selector";
import BlogMsgBox from "../../components/blogMsgBox/BlogMsgBox";
import Pagination from "../../components/pagination/Pagination";

function Blog() {

    const [contents, setContents] = useState([]);
    const contentsDatas = useRecoilValueLoadable(pageContentSelector("blog"));
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const blogDatas = useRecoilValueLoadable(blogSelector(page));


    const requestFetchContents = useCallback(() => {
        if (contentsDatas === null || contentsDatas === undefined) {
            return;
        }
        switch (contentsDatas.state) {
            case 'hasValue':
                const result = contentsDatas.contents;
                const contents = result.contents;
                setContents(contents);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw contentsDatas.contents;
        }
    }, [setContents, contentsDatas]);

    const requestFetchBlog = useCallback(() => {
        if (blogDatas === null || blogDatas === undefined) {
            return;
        }
        switch (blogDatas.state) {
            case 'hasValue':
                const result = blogDatas.contents;
                const blogs = result.contents;
                setBlogs(blogs);
                setTotalPages(result.totalPages);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw blogDatas.contents;
        }
    }, [setBlogs, blogDatas]);

    useEffect(() => {
        requestFetchContents();
    }, [requestFetchContents]);

    useEffect(() => {
        requestFetchBlog();
    }, [requestFetchBlog, page]);

    const handleSearchUser = () => {
        console.log(`현재 페이지:${page}`);
    }

    const setCurrentPageNumber = (page) => {
        setPage(page);
    }

    return (
        <main className="blogSection">
            {contents.map((list, index) => (
                <PageThumbnail
                    key={index}
                    imgUrl={`/download/${list.blog_header_image}`}
                    description={list.blog_title}
                    miniNavProps={
                        {
                            parentMenu: {
                                title: "STORY",
                            },
                            childMenu: {
                                title: "BLOG",
                                menuId: 2,
                            }
                        }
                    }
                />
            ))}

            <section className="blogSection__contentsWrap blogContainer">
                <div className="blogSection__contentsWrap__titleWrap">
                    <div className="blogSection__contentsWrap__titleWrap__title">
                        <h1>BLOG</h1>
                        <h3>아카데미 BLOG</h3>
                    </div>

                </div>



                <div className="blogSection__contentsWrap__videoGrid">
                    {blogs.map(list => (
                        <React.Fragment key={list.id}>
                            <BlogMsgBox data={list} />
                        </React.Fragment>
                    ))}
                    {blogs.length === 0 &&
                        <>
                            <div />
                            <h1 className="blogSection__contentsWrap__noSearch">조회된 블로그가 없습니다.</h1>
                            <div />
                        </>
                    }

                </div>
                {/*<p>총 페이지수: {totalPages}</p><p>현재 페이지: {page}</p>*/}
                <Pagination
                    totalPages={totalPages}
                    handleSearchUser={handleSearchUser}
                    setCurrentPageNumber={setCurrentPageNumber}
                />
            </section>


        </main >
    )
}

export default Blog;