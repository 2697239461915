import "../../pages/TrainingCourse/trainingDetail.scss";

function ScreeningItem({img, title, contents}) {
    return (
        <div className="detailSection--contentsWrap__infoWrap--eachSection--screenWrap--itemBox">
            <img src={img} alt=""/>
            {title}

            {contents}


        </div>
    )
}

export default ScreeningItem;