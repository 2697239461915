import "./mobNav.scss"
import { Collapse, DialogContent, DialogTitle, List, ListItemButton, ListItemText } from '@mui/material';
import Dialogs from '@mui/material/Dialog';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import {pageMenuSelector, codeSelector} from "../../../store/Selector";
import { isDialogOpenAtom } from '../../../store/Atom';
import { Link } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, {useCallback, useEffect, useState} from "react";

function MobNav() {
  const [menus, setMenus] = useState([]);
  const menuData = useRecoilValueLoadable(pageMenuSelector);

  const [codes, setCodes] = useState([]);
  const codeDatas = useRecoilValueLoadable(codeSelector("SDS0001"));

  const [isDialogOpen, setIsDialogOpen] = useRecoilState(isDialogOpenAtom);

  const requestFetchMenus = useCallback(() => {
    if (menuData === null || menuData === undefined) {
      return;
    }
    switch (menuData.state) {
      case 'hasValue':
        const result = menuData.contents;
        const contents = result.contents;
        setMenus(contents[0].subPages);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw menuData.contents;
    }
  }, [setMenus, menuData]);

  const requestFetchCodes = useCallback(() => {
    if (codeDatas === null || codeDatas === undefined) {
      return;
    }
    switch (codeDatas.state) {
      case 'hasValue':
        const result = codeDatas.contents;
        const contents = result.contents;
        setCodes(contents[0]);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw codeDatas.contents;
    }
  }, [setCodes, codeDatas]);

  useEffect(() => {
    requestFetchMenus();
  }, [requestFetchMenus]);

  useEffect(() => {
    requestFetchCodes();
  }, [requestFetchCodes]);

  return (
    <Dialogs 
      fullScreen={true} 
      onClose={() => setIsDialogOpen(false)} 
      open={isDialogOpen}
      className="mobNavSection"
    >
      <DialogTitle className="mobNavSection__header">
        <div />
        {/* <img src={require("../../../assets/icons/logo-shinhanDS-black.svg").default} alt=""/> */}
        <img 
          src={require("../../../assets/icons/ico-close.png")} alt="" 
          onClick={() => setIsDialogOpen(false)}
          className="mobNavSection__header--closeBtn" 
         />
      </DialogTitle>
      <DialogContent className="mobNavSection__body">
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {menus.map((list, index) => (
              <MenuList
                  key={index}
                  setIsDialogOpen={setIsDialogOpen}
                  parentMenu={{
                    title: `${list.title}`,
                    routePath: `/${list.url}`
                  }}
                  childMenu={list.subPages}
                  childMenu2={codes}
                  idx={index}/>
              ))}
        </List>
      </DialogContent>
    </Dialogs>
  );
}

function MenuList({ setIsDialogOpen, parentMenu, childMenu, childMenu2, idx}) {
  const [open, setOpen] = useState(false);
  
  const handleClick = () => {
    setOpen(!open);
  };

  return(
    <React.Fragment key={parentMenu.title}>
      <ListItemButton onClick={handleClick}>
        <ListItemText 
          primary={parentMenu.title} 
          primaryTypographyProps={{ fontFamily: "나눔바른고딕", fontSize: 18, fontWeight: "bold" }} 
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {idx !== 1 && childMenu.map(menu => {
            if (!menu.isHidden)
              return <Link key={menu.url} to={menu.url}>
                      <ListItemButton onClick={() => {
                        setIsDialogOpen(false)
                      }} sx={{pl: 4}}>
                        <ListItemText
                            primary={menu.title}
                            primaryTypographyProps={{fontFamily: "나눔바른고딕", fontSize: 16, fontWeight: "bold"}}
                        />
                      </ListItemButton>
                    </Link>
            return <></>
          })}
          {idx === 1 && childMenu2.map((menu, index) => {
            return <Link key={index} to={`/trainingCourse/${menu.codeValue}`}>
              <ListItemButton onClick={() => {
                setIsDialogOpen(false)
              }} sx={{pl: 4}}>
                <ListItemText
                    primary={menu.codeName}
                    primaryTypographyProps={{fontFamily: "나눔바른고딕", fontSize: 16, fontWeight: "bold"}}
                />
              </ListItemButton>
            </Link>
          })}
        </List>
      </Collapse>
    </React.Fragment>
  )
}

export default MobNav;