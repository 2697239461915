import "./Post.scss";

import React from "react";
import DaumPostcode from "react-daum-postcode";

const Post = (props) => {

    const complete = (data) =>{
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        // console.log(data);
        // console.log(fullAddress);
        // console.log(data.zonecode);


        props.onClose(data.zonecode, fullAddress);
    }

    return (
        <div className="postSection">
            <DaumPostcode
                className="postmodal"
                autoClose
                onComplete={complete} />
        </div>
    );
};

export default Post;