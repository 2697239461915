import "./registration.scss";

import React, {useCallback, useEffect, useState} from "react";
import {lectureDetailSelector, pageContentSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";
import {useParams, useNavigate} from "react-router-dom";


import {useForm} from "react-hook-form";
import DetailThumbnail from "../../components/detailThumbnail/DetailThumbnail";


function RegistrationCheck() {
    const {
        register,
        handleSubmit ,
        setValue,
        formState: { isSubmitting, isSubmitted, errors}
    } = useForm();


    let params = useParams();
    const navigate = useNavigate();
    const [contents, setContents] = useState([]);
    const contentsDatas = useRecoilValueLoadable(pageContentSelector("trainingDetail"));

    const lectureData = useRecoilValueLoadable(lectureDetailSelector(params?.lectureId));
    const [lecture, setLecture] = useState([]);

    const requestFetchContents = useCallback(() => {
        if (contentsDatas === null || contentsDatas === undefined) {
            return;
        }
        switch (contentsDatas.state) {
            case 'hasValue':
                const result = contentsDatas.contents;
                const contents = result.contents;
                setContents(contents);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw contentsDatas.contents;
        }
    }, [setContents, contentsDatas]);

    const requestFetchLecture = useCallback(() => {
        if (lectureData === null || lectureData === undefined) {
            return;
        }
        switch (lectureData.state) {
            case 'hasValue':
                const result = lectureData.contents;
                const lectures = result.contents;
                //console.log(lectures);
                setLecture(lectures[0]);
                //console.log(lecture);
                // console.log(lecture.id);
                // setValue("lectureId", lecture.id);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw lectureData.contents;
        }
    }, [setLecture, lectureData]);

    useEffect(() => {
        requestFetchContents();
    }, [requestFetchContents]);

    useEffect(() => {
        requestFetchLecture();
        // console.log(lecture.id);
        // setValue("lectureId", lecture.id);
    }, [requestFetchLecture, params?.lectureId]);

    useEffect(() => {
        if (lecture) {
            setValue("lectureId", lecture.id);

        }
    }, [lecture]);

    const submitForm = (data) => {
        console.log("1:", data);
        const handphone = data.mobile1 + "-" + data.mobile2 + "-" + data.mobile3;
        data = { ...data, handphone};
        console.log("2:", data);
        const json = JSON.stringify(data);

        return fetch(`/api/registration/search`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: json,

        }).then((response) => {
            if (response.ok) {
                console.log("수신 성공");
                //console.log(response.json());
                //alert("훈련 신청이 완료되었습니다.");
                //navigate("/trainingDetail/" + lectureId);
                const result = response.json();
                result.then(function(res) {
                    if (res.contents == null) {
                        alert("해당 과정의 접수 내역이 존재하지 않습니다.");
                        setValue("pwd", "");
                    } else {
                        //alert("OK!");
                        const memberInfo = res.contents[0];
                        // const memberId = res.contents[0].memberId;
                        // const memberUid = res.contents[0].memberUid;
                        console.log(memberInfo);
                        sessionStorage.setItem("uid", memberInfo.memberUid);
                        navigate("/registrationShow/" + memberInfo.lectureId + "/" + memberInfo.memberId);
                    }
                })
            } else {
                console.error("Error 메시지를 확인하세요.")
            }
        })
    }

    return (
        <main className="registrationSection">
            {contents.map((list, idx) => (
                <DetailThumbnail
                    key={lecture.id}
                    defaultImgUrl={`/download/${list.trainingDetail_header_image}`}
                    imgUrl={lecture.backgroundUrl}
                    lecture={lecture}
                    miniNavProps={
                        {
                            parentMenu: {
                                title: "훈련 과정",
                            },
                            childMenu: {
                                title: lecture.lectureType === '01' ? "취업 훈련" : (lecture.lectureType === '02' ? "직무 훈련" : "원격 훈련"),
                                menuId: 1,
                            }
                        }
                    }
                />
            ))}
            <section className="registrationSection__contentsWrap container">
                <div className="registrationSection__contentsWrap__titleWrap">
                    <div className="registrationSection__contentsWrap__titleWrap__title">
                        <h1>{lecture.title}</h1>
                        <h3>수강신청 확인 폼입니다</h3>
                    </div>
                </div>
                <div className="registrationSection__contentsWrap__registerForm">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="titleType10">
                            <span>취업을 위한 교육과정 접수내역을 확인하시려면 수강신청서 작성시 입력한 정보로 조회 가능합니다.</span>
                        </div>
                        <div className="viewType01 inputTb2 border photoUse marB_50">
                            <table>
                                <colgroup>
                                    <col id="title3" />
                                    <col />
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>한글성명 <span className="rq">*</span></th>
                                    <td>
                                        <input type="hidden" id="lectureId" name="lectureId" {...register("lectureId")} />
                                        <input className="size01 gray" type="text" name="ireum_kor" readOnly="" id="ireum_kor"
                                               aria-invalid={isSubmitted ? (errors.uname ? "true" : "false") : undefined}
                                               {...register("uname", {
                                                   required: "한글성명은 필수 입력입니다."
                                               })} />
                                        {errors.uname && <small role="alert">{errors.uname.message}</small> }
                                    </td>

                                </tr>

                                <tr>
                                    <th>핸드폰 <span className="rq">*</span></th>
                                    <td>
                                        <input className="sel01" type="text" name="mobile1" id="mobile1" maxLength="3"
                                               aria-invalid={isSubmitted ? (errors.mobile1 ? "true" : "false") : undefined}
                                               {...register("mobile1", {
                                                   required: "핸드폰 번호1은 필수 입력입니다."
                                               })}/>
                                        <span className="dash2">-</span>
                                        <input className="sel01" type="text" name="mobile2" id="mobile2" maxLength="4"
                                               aria-invalid={isSubmitted ? (errors.mobile2 ? "true" : "false") : undefined}
                                               {...register("mobile2", {
                                                   required: "핸드폰 번호2는 필수 입력입니다."
                                               })}/>
                                        <span className="dash2">-</span>
                                        <input className="sel01" type="text" name="mobile3" id="mobile3" maxLength="4"
                                               aria-invalid={isSubmitted ? (errors.mobile3 ? "true" : "false") : undefined}
                                               {...register("mobile3", {
                                                   required: "핸드폰 번호3은 필수 입력입니다."
                                               })}/>
                                        {errors.mobile1 && <><br /><small role="alert">{errors.mobile1.message}</small></> }
                                        {errors.mobile2 && <><br /><small role="alert">{errors.mobile2.message}</small></> }
                                        {errors.mobile3 && <><br /><small role="alert">{errors.mobile3.message}</small></> }

                                    </td>
                                </tr>
                                <tr>
                                    <th>접수내역 확인번호 <span className="rq">*</span></th>
                                    <td>
                                        <input type="password" placeholder="비밀번호 입력" name="jeopsu_pw" id="jeopsu_pw"
                                               aria-invalid={isSubmitted ? (errors.pwd ? "true" : "false") : undefined}
                                               {...register("pwd", {
                                                   required: "비밀번호는 필수 입력입니다.",

                                               })}
                                        />
                                        {errors.pwd && <><br /><small role="alert">{errors.pwd.message}</small></> }
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="btnCenter">

                            <button className="btType01 blue" type="submit" disabled={isSubmitting}>확인</button>

                        </div>
                    </form>
                </div>

            </section>
        </main>
    )

}

export default RegistrationCheck;