import { Link } from "react-router-dom";
import "./trainingCourseBox.scss";
import moment from "moment/moment";


function TrainingCourseBox ({ data }) {

  return (
    <>

        <section 
          className="trainingCourseBoxSection hvr-float"
        >

          <div className="trainingCourseBoxSection__contentsWrap">
            <Link to={`/trainingDetail/${data.id}`}>
              <img src={data.thumbnailUrl} alt=""/>
            </Link>
          </div>
        </section>

    </>
  )
}

export default TrainingCourseBox;