import "./videoMsg.scss";

function VideoMsgBox ({ data, videoOpenfn }) {

  return (
    <>
      <section className="videoMsgBoxSection">
        <div className="videoMsgBoxSection__imgWrap" onClick={() => videoOpenfn(data.videoUrl)}>
          <img src={data.thumbnailUrl} alt=""/>
        </div>
        <h1>{data.title.split('\n').map((item,idx)=>{
          return (
              <span key={idx}>{item}<br/></span>
          )
        })}</h1>
        {data.content !== null && <>
            {data.content.split('\n').map((item,idx)=>{
              return (
                  <span key={idx}>{item}<br/></span>
              )
            })}
        </>}
        <div className="videoMsgBoxSection__tagsWrap">
          {data.tags.map((list, idx) => (
            <div key={idx} className="videoMsgBoxSection__tagsWrap--tag">#{list}</div>
          ))}
        </div>
      </section>
    </>
  )
}


export default VideoMsgBox;