import { useRecoilValueLoadable } from "recoil";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import TrainingCourseBox from "../../components/trainingCourseBox/TrainingCourseBox";
//import { trainingCourseDatasAtom } from "../../store/Atom";
import {pageContentSelector, lectureSelector, codeSelector} from "../../store/Selector";
import "./trainingCourse.scss";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";


function TrainingCourse () {

  let params = useParams();

  const [contents, setContents] = useState([]);
  const [codes, setCodes] = useState([]);
  const [category, setCategory] = useState("");
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("trainingCourse"));
  const codeDatas = useRecoilValueLoadable(codeSelector("SDS0004"));
  const lectureDatas = useRecoilValueLoadable(lectureSelector([params?.lectureType, category]));
  const [lectures, setLectures] = useState([]);

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  const requestFetchCodes = useCallback(() => {
    if (codeDatas === null || codeDatas === undefined) {
      return;
    }
    switch (codeDatas.state) {
      case 'hasValue':
        const result = codeDatas.contents;
        const contents = result.contents;
        setCodes(contents[0]);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw codeDatas.contents;
    }
  }, [setCodes, codeDatas]);

  const requestFetchLecture = useCallback(() => {
    if (lectureDatas === null || lectureDatas === undefined) {
      return;
    }
    switch (lectureDatas.state) {
      case 'hasValue':
        const result = lectureDatas.contents;
        const lectures = result.contents;
        setLectures(lectures);

        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw lectureDatas.contents;
    }
  }, [setLectures, lectureDatas, category]);

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  useEffect(() => {
    requestFetchCodes();
  }, [requestFetchCodes]);

  useEffect(() => {
    requestFetchLecture();
  }, [requestFetchLecture, params?.lectureType]);

  return (
    <main className="courseSection">
      {contents.map(list => (
        <PageThumbnail
          imgUrl={`/download/${list.trainingCourse_header_image}`}
          description={list.trainingCourse_title}
          miniNavProps={
            {
              parentMenu: {
                title: "훈련 과정",
              },
              childMenu: {
                title: params?.lectureType === '01' ? "취업 훈련" : (params?.lectureType === '02' ? "직무 훈련" : "원격 훈련"),
                menuId: 1,
              }
            }
          }
        />
      ))}

      <section className="courseSection--contentsWrap container--w100">
        <div className="courseSection--contentsWrap__titleWrap">
          <h2>K-Digital Training</h2>
          <h3>대한민국 금융IT를 선도해 나갈 인재를 모집합니다.</h3>
        </div>

        <div className="courseSection--contentsWrap__courseWrap">


          <div className="courseSection--contentsWrap__courseWrap--boxArea container--nopd">
            <div className="courseSection--contentsWrap__courseWrap--absoluteIcon">
              {codes.map(list => (
                  <span className="iconBtn" onClick={() => {
                    setCategory(list.codeValue);
                  }}>{list.codeName}</span>
              ))}
            </div>
            <div className="courseSection--contentsWrap__courseWrap--absolute">
              {lectures.map(list => (
                <TrainingCourseBox key={list.id} data={list} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </main>
    )
}

export default TrainingCourse;