import "./introduction.scss";
import React, {useCallback, useEffect, useState} from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import {pageContentSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";

function Introduction() {
  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("introduction"));

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  return(
    <main className="introductionSection">
      {contents.map(list => (
          <PageThumbnail
              imgUrl={`/download/${list.intro_header_image}`}
              description={list.intro_title}
              miniNavProps={
                {
                  parentMenu: {
                    title: "사업 소개",
                  },
                  childMenu: {
                    title: "훈련기관소개",
                    menuId: 0,

                  }
                }
              }
          />
      ))}


      <section className="introductionSection__contentsWrap container">
        <div className="introductionSection__contentsWrap__titleWrap">
          <h1>훈련 기관 소개</h1>
        </div>
        <div className="introductionSection__contentsWrap__boxGrid">
          <div className="introductionSection__contentsWrap__boxGrid--box">
            <div className="introductionSection__contentsWrap__boxGrid--box__text">
              <h2>디지털선도기업</h2>
              <h1>(주)신한DS</h1>
              <p>
                신한DS는 1991년 설립 이후 신한금융그룹 <br />
                IT자회사로서 30년간 금융권에 최적화된 솔루션 및 <br />
                서비스를 제공해왔으며, <br />
                지속적인 역량 축적과 사업 확대를 통해 <br />
                금융 IT를 선도하고 있습니다.
              </p>
            </div>
            <div className="introductionSection--viewMoreBtn" onClick={() => window.open("https://www.shinhands.co.kr")}>
              공식홈 바로가기 +
            </div>
          </div>
          <div className="introductionSection__contentsWrap__boxGrid--box">
            <img src={require("../../assets/images/intorduce-boxImg.png")} alt=""/>
          </div>
          <div className="introductionSection__contentsWrap__boxGrid--box">
            <div className="introductionSection__contentsWrap__boxGrid--box__text">
              <h2>운영지원기관</h2>
              <h1>대한상공회의소</h1>
              <p>
                직업능력개발, 취업역량 강화 사업 등을 <br />
                수행하는 HRD 교육기관으로 <br />
                전국 7개 인력개발원에서 직업 훈련 노하우를 바탕으로 <br />
                산업과 기업 수요를 반영한 융복합형 전문 인력을 양성하고 있습니다. <br />
              </p>
            </div>
            <div className="introductionSection--viewMoreBtn" onClick={() => window.open("http://www.korchamhrd.net")}>
              공식홈 바로가기 +
            </div>
          </div>
          <div className="introductionSection__contentsWrap__boxGrid--box">
            <div className="introductionSection__contentsWrap__boxGrid--box__text">
              <h2>파트너기관</h2>
              <h1>서울기술교육센터</h1>
              <p>
                서울기술교육센터는 대한상공회의소 산하기관으로 <br />
                서울지역 최초로 시스템반도체, MES, AI 분야 양성과정을 개설하는 등 <br />
                기업 수요에 부응하는 산학일체형 기술인력 양성에 매진하고 있습니다. <br />
              </p>
            </div>
            <div className="introductionSection--viewMoreBtn" onClick={() => window.open("http://www.kccistc.net")}>
              공식홈 바로가기 +
            </div>
          </div>
        </div>
      </section>
    </main >
  )
}

export default Introduction;