//import "../../pages/Home/home.scss";
import "../videoMsgDialog/videoMsgDialog.scss";
import { useRecoilState } from "recoil";
import { isVideoDialogOpenAtom } from "../../store/Atom";
import { Backdrop } from "@mui/material";

function VideoDialog() {
  const [videoOpen, setVideoOpen] = useRecoilState(isVideoDialogOpenAtom);


  return(
    <Backdrop
        className="videoMsgDialogSection"
        onClick={() => setVideoOpen({isOpen: false, url: "#"})}
        open={videoOpen.isOpen}
      >
        <div className="videoMsgDialogSection__frame">
            <img
                className="videoMsgDialogSection__frame--closeBtn"
                src={require("../../assets/icons/ico-close.png")}
                alt=""
            />
            <div className="videoMsgDialogSection__frame--videoWrap">

                <iframe
                    src={`https://www.youtube.com/embed/${videoOpen?.url}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                > </iframe>

            </div>
        </div>
    </Backdrop>
  )
}

export default VideoDialog;