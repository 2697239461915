import "./blogDetail.scss";
import React, {useCallback, useEffect, useState, useRef} from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import {useRecoilValueLoadable} from "recoil";
import {blogDetailSelector, pageContentSelector} from "../../store/Selector";
import { Parser } from "html-to-react";
import {useParams} from "react-router-dom";

function BlogDetail() {
    let params = useParams();
    const [contents, setContents] = useState([]);
    const contentsDatas = useRecoilValueLoadable(pageContentSelector("blog"));
    const scrollTopPointRef = useRef(null);
    //const createMarkup = htmlString => ({ __html: htmlString });

    // const paths = window.location.href.split('/');
    // const id = paths[paths.length - 1];



    const blogData = useRecoilValueLoadable(blogDetailSelector(params?.blogId));
    const [blog, setBlog] = useState({});

    const requestFetchContents = useCallback(() => {
        if (contentsDatas === null || contentsDatas === undefined) {
            return;
        }
        switch (contentsDatas.state) {
            case 'hasValue':
                const result = contentsDatas.contents;
                const contents = result.contents;
                setContents(contents);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw contentsDatas.contents;
        }
    }, [setContents, contentsDatas]);

    const requestFetchBlog = useCallback(() => {
        if (blogData === null || blogData === undefined) {
            return;
        }
        switch (blogData.state) {
            case 'hasValue':
                const result = blogData.contents;
                const blogs = result.contents;
                setBlog(blogs[0]);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw blogData.contents;
        }
    }, [setBlog, blogData]);


    useEffect(() => {
        requestFetchContents();
    }, [requestFetchContents]);

    useEffect(() => {
        requestFetchBlog();
    }, [requestFetchBlog, params?.blogId]);


    return (
        <main className="blogSection">
            {contents.map((list, index) => (
                <PageThumbnail
                    key={index}
                    imgUrl={`/download/${list.blog_header_image}`}
                    description={list.blog_title}
                    miniNavProps={
                        {
                            parentMenu: {
                                title: "STORY",
                            },
                            childMenu: {
                                title: "BLOG",
                                menuId: 2,
                            }
                        }
                    }
                />
            ))}

            <section className="blogSection--contentsWrap container--w100">
                <div className="scrollTopPoint" ref={scrollTopPointRef}/>
                <div className="blogSection--contentsWrap__primarySection container">
                    <div className="blogSection--contentsWrap__primarySection--inner">
                        <div className="blogSection--contentsWrap__primarySection--textWrap">
                            <div className="blogSection--contentsWrap__primarySection--textWrap__titleWrap">

                                <div
                                    className="blogSection--contentsWrap__primarySection--textWrap__titleWrap--titleBox">
                                    <div
                                        className="blogSection--contentsWrap__primarySection--textWrap__titleWrap--titleBox__left">
                                        <h1>{blog.title}</h1>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<img src={blog.thumbnailUrl} alt=""/>*/}

                    </div>

                </div>

                <div className="blogSection--contentsWrap__primarySection--textWrap__paragraphWrap">
                    <div>{Parser().parse(blog.blogBody)}</div>
                    {/*<div dangerouslySetInnerHTML={createMarkup(blog.blogBody)}></div>*/}
                </div>
            </section>
            {/* scrollTop btn */}
            <div
                className="detailSection--scrollTopBtn"
                onClick={() => scrollTopPointRef.current?.scrollIntoView({ behavior: 'smooth' })}
            >
                <img src={require("../../assets/icons/ico-right-arrow.png")} alt="" />
            </div>
        </main>
    )
}

export default BlogDetail;