import "./trainingFacility.scss";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React, {useCallback, useEffect, useState} from "react";
import Slider from "react-slick";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import {facilitySelector, pageContentSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";

const { kakao } = window;

function TrainingFacility () {

  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("trainingFacility"));
  const facilityDatas = useRecoilValueLoadable(facilitySelector);
  const [facilities, setFacilities] = useState([]);

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  const requestFetchFacility = useCallback(() => {
    if (facilityDatas === null || facilityDatas === undefined) {
      return;
    }
    switch (facilityDatas.state) {
      case 'hasValue':
        const result = facilityDatas.contents;
        const facilities = result.contents;
        //console.log(facilities);
        setFacilities(facilities);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw facilityDatas.contents;
    }
  }, [setFacilities, facilityDatas]);

  // slider custom btn
  const CustomNextArrow = (props) => {
    const { onClick } = props;

    return(
      <div className={`slick-next customArrowWrap`} onClick={onClick}>
        <img src={require("../../assets/icons/ico-right-arrow.png")} alt="" />
      </div>
    )
  }
  const CustomPrevArrow = (props) => {
    const { onClick } = props;

    return(
      <div className={`slick-prev customArrowWrap prevArrow`} onClick={onClick}>
        <img src={require("../../assets/icons/ico-right-arrow.png")} alt="" />
      </div>
    )
  }

  const settings = {
    customPaging: (i) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <div className="cutomPagingWrap">
          <img src={`/download/${facilities[i].fileName}`} alt=""/>
        </div>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    autoplay: true,
    fade:true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  useEffect(() => {
    requestFetchFacility();
  }, [requestFetchFacility]);

  useEffect(() => {

  // Kakao Map
  try {
      const container = document.getElementById("kakaoMap");
      const lat = 37.5593346;
      const lng = 126.9226685;
      const options = {
        center: new kakao.maps.LatLng(lat, lng),
        level: 2
      }
      const map = new kakao.maps.Map(container, options);

      const markerPosition  = new kakao.maps.LatLng(lat, lng);
      const marker = new kakao.maps.Marker({
        position: markerPosition,
      });

      marker.setMap(map);
    } catch (error) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


    return (
    <main className="facilitySection">
      {contents.map((list, idx) => (
        <PageThumbnail
            key={idx}
          imgUrl={`/download/${list.trainingFacility_header_image}`}
          description={list.trainingFacility_title}

          miniNavProps={
            {
              parentMenu: {
                title: "훈련 센터",
              },
              childMenu: {
                title: "훈련 시설",
                menuId: 3,
              }
            }
          }
        />
      ))}

      <section className="facilitySection--contentsWrap container">
        <section className="facilitySection--contentsWrap__primarySection">
          <div className="facilitySection--contentsWrap__primarySection--circle">다양한 편의시설</div>
          <div className="facilitySection--contentsWrap__primarySection--circle">편안한 휴게시설</div>
          <div className="facilitySection--contentsWrap__primarySection--circle">최신형 노트북 <br /> 제공</div>
          <div className="facilitySection--contentsWrap__primarySection--circle">쾌적한 강의실</div>
        </section>

        <section className="facilitySection--contentsWrap__secondarySection">
          <h2>금융 SW 교육 장소</h2>
          <div className="facilitySection--contentsWrap__secondarySection--slideWrap">
            <Slider {...settings}>
              {facilities.map(list => (
                  <div className="sliderInner-box" key={list.id}>
                    <img src={`/download/${list.fileName}`} alt={list.title} />
                  </div>
              ))}


            </Slider>
          </div>
        </section>

        <section className="facilitySection--contentsWrap__mapSection">
          <div className="facilitySection--contentsWrap__mapSection--titleWrap">
            <h2>오시는 길</h2>
            <h4>편하고 쉽게 찾으실 수 있도록 오시는 길을 안내합니다.</h4>
          </div>
          <div className="facilitySection--contentsWrap__mapSection--mapWrap">
            <div id="kakaoMap"/>
            <div className="facilitySection--contentsWrap__mapSection--mapWrap__infoWrap">
              <div className="infoBox">
                <img src={require("../../assets/icons/ico-map.png")} alt=""/>
                <h3>교육장 위치</h3>
                <p>(03993)서울특별시 마포구 월드컵북로 4길 77, 1층</p>
              </div>
              <div className="infoBox">
                <img src={require("../../assets/icons/ico-metro.png")} alt=""/>
                <h3>교통 안내</h3>
                <p>
                  지하철 : 홍대입구역 2번 출구 도보 5분< br/>
                  버&nbsp;&nbsp;&nbsp;스 : 홍대입구역 중앙차로 하차 도보 5분
                </p>
                <span>&#8251; 자가 차량 이용시 건물 내 주차 지원은 불가 합니다.</span>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
    )
}

export default TrainingFacility;