import "./archiveMsg.scss";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import React from "react";

function ArchiveMsgBox({ data }) {

    return (
        <>
            <section className="archiveMsgBoxSection">
                <div className="item_inner">
                    <div className="info_post">
                        <h1>
                                {data.title}
                        </h1>
                        <div className="text">
                                {data.content}
                        </div>
                        <div className="date">
                            <em>{data.createdAt.substr(0, 10)}</em>
                        </div>

                    </div>
                    <div className="thumbnail_post">
                        <a href={`/downloadFile/${data.fileName}`}
                            download={data.fileName}
                            target="_blank"
                            rel="noreferrer">
                            <FontAwesomeIcon icon={faFloppyDisk}/>
                        </a>

                    </div>
                </div>

            </section>
        </>
    )
}

export default ArchiveMsgBox;