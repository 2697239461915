import "./blogMsg.scss";
import {Link} from "react-router-dom";

function BlogMsgBox({ data }) {
    //const createMarkup = htmlString => ({ __html: htmlString });
    const regex = /(<([^>]+)>)/gi;
    let result = data.blogBody.replace(regex, "");
    result = result.replaceAll("&nbsp;", "");
    const detailUrl = `/blogDetail/${data.id}`;
    return (
        <>
            <section className="blogMsgBoxSection">
                <div className="item_inner">
                    <div className="info_post">
                        <h1>
                            <Link to={detailUrl}>
                                {data.title}
                            </Link>

                        </h1>
                        <div className="text">
                            <Link to={detailUrl}>
                                {result}
                            </Link>
                        </div>
                        <div className="date">
                            <em>{data.createdAt.substr(0, 10)}</em>
                        </div>

                    </div>
                    <div className="thumbnail_post">
                        <Link to={detailUrl} className="thumbnail_inner">
                            <img src={data.thumbnailFileName} alt=""/>
                        </Link>

                    </div>
                </div>

            </section>
        </>
    )
}

export default BlogMsgBox;