import Router from "./pages/Router";
import "./common/css/reset.scss"

function App() {
  return (
  <div style={{width: "100%", display: "flex", flexDirection:"column", alignItems: "center"}}>
        <Router />
  </div>
  );
}

export default App;
