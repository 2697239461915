import "./benenfitSliderBox.scss";


function BenefitSliderBox({ title, iconUrl, contents }) {

  return(
    <section className="benefitSliderBox">
      <div className="benefitSliderBox__titleWrap">
        <h1>{title}</h1>
        <img src={require(`../../assets/images/courseDetailImg/slideicon/${iconUrl}`)} alt=""/>
      </div>
      <div className="benefitSliderBox__contents">{contents}</div>
    </section>
  )
}

export default BenefitSliderBox;