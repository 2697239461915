import "./inquiry.scss";
import React, {useCallback, useEffect, useState} from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import {recentLectureSelector, pageContentSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";
import moment from "moment/moment";
import {Link} from "react-router-dom";

function Inquiry() {

  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("inquiry"));

  const lectureDatas = useRecoilValueLoadable(recentLectureSelector);
  const [lectures, setLectures] = useState([]);

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  const requestFetchLecture = useCallback(() => {
    if (lectureDatas === null || lectureDatas === undefined) {
      return;
    }
    switch (lectureDatas.state) {
      case 'hasValue':
        const result = lectureDatas.contents;
        const lectures = result.contents;
        setLectures(lectures);

        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw lectureDatas.contents;
    }
  }, [setLectures, lectureDatas]);

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  useEffect(() => {
    requestFetchLecture();
  }, [requestFetchLecture]);

  return(
    <main className="inquirySection">
      {contents.map(list => (
        <PageThumbnail
          imgUrl={`/download/${list.inquiry_header_image}`}
          description={list.inquiry_title}
          miniNavProps={
            {
              parentMenu: {
                title: "훈련 센터",
              },
              childMenu: {
                title: "문의 및 지원",
                menuId: 3,
              }
            }
          }
        />
      ))}

      <section className="inquirySection__contentsWrap container">
        <div className="inquirySection__contentsWrap__titleWrap">
          {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
          <h1></h1>
        </div>

        {lectures.map(list => (
            <div className="inquirySection__contentsWrap__enterSection">
              <div className="inquirySection__contentsWrap__enterSection--infoWrap">
                <h1>
                  {list.title} <br />
                  서류 접수가 시작되었습니다.
                </h1>
                <h4>{moment(list.applyStartDate).format("YYYY.MM.DD")} ~ {moment(list.applyEndDate).format("YYYY.MM.DD")}</h4>

              </div>
              <div className="inquirySection__contentsWrap__enterSection--btnWrap">
                <Link to={`/registration/${list.id}`}>
                  <div className="inquirySection__contentsWrap__enterSection--btnWrap__btn">접수하기</div>
                </Link>
              </div>
            </div>
        ))}
        <div className="inquirySection__contentsWrap__enterSection">
          <div className="inquirySection__contentsWrap__enterSection--infoWrap">
            <span>신청 접수는 신한 금융SW 아카데미 온라인에서 가능하며, 기간 외 신청은 받지않으므로 기한(시간) 엄수 바랍니다.</span>
          </div>
        </div>


        <div className="inquirySection__contentsWrap__contactSection">
          <div className="inquirySection__contentsWrap__contactSection__itemBox">
            <div className="inquirySection__contentsWrap__contactSection__itemBox--iconBox">
              <img src={require("../../assets/icons/ico-phone.png")} alt=""/>
              <h2>과정 문의</h2>
            </div>
            <div className="inquirySection__contentsWrap__contactSection__itemBox--textBox">
              <p>상담 전화 <span className="masterColorPoint">02-6392-0044</span></p>
              <p>상담 시간 <span className="notBold textColorGrayPoint">평일 10:00~17:00</span></p>
              <p className="notBold textColorGrayPoint">(점심시간 제외 13:00~14:00)</p>
            </div>
          </div>

          <div className="inquirySection__contentsWrap__contactSection__itemBox--iconBox cursorPointer">
            <div>
              <Link to={`/oneToOne`}>
                <img src={require("../../assets/icons/ico-mail.png")} alt=""/>
              </Link>
            </div>
            <div>
              <Link to={`/oneToOne`}>
                <h2 >1 : 1 문의</h2>
              </Link>
            </div>
          </div>

          <div className="inquirySection__contentsWrap__contactSection__itemBox">
            <div className="inquirySection__contentsWrap__contactSection__itemBox--iconBox">
              <img src={require("../../assets/icons/ico-social.png")} alt=""/>
              <h2>방문상담</h2>
            </div>
            <div className="inquirySection__contentsWrap__contactSection__itemBox--textBoxRight">
              <p className="textColorGrayPoint">(03993) 서울특별시 마포구 월드컵북로 4길 77, 1층</p>
              <p className="textColorGrayPoint">(홍대입구역 2번 출구 도보 5분)</p>
            </div>
          </div>
        </div>
      </section>
    </main >
  )
}

export default Inquiry;