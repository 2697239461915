import "./courseDetailWidget.scss";
import moment from "moment";
import 'moment-timezone';
import { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";


function CourseDetailWidget ({applyStartDate, applyEndDate, startDate, lectureId}) {
  const navigate = useNavigate();
  const [ dday, setDday ] = useState(0);
  const [isHidden, setIsHidden] = useState(false);
  // const [applyEndDate, setApplyEndDate] = useState("");
  // const [startDate, setStartDate] = useState("");

  const dDayCountdown = () => {
    let daysLeft;
    let daysLeftStr;
    const targetDate = moment.tz(applyEndDate, 'Asia/Seoul');
    targetDate.set({'hour': 23, 'minute': 59, 'second': 59});
    const currentDate = moment.tz('Asia/Seoul');
    daysLeft = targetDate.diff(currentDate, 'days');

    if (daysLeft < 0) {
        daysLeftStr = '+';
        daysLeft = -daysLeft;
    } else {
        daysLeftStr = '-';
    }

    if(daysLeft < 10){
        daysLeftStr += `0${daysLeft}`;
    } else {
        daysLeftStr += `${daysLeft}`;
    }

    return daysLeftStr;
  };


  useEffect(() => {
    // console.log(data);
    // setApplyEndDate(data.applyEndDate);
    // setStartDate(data.startDate);
    const daysLeft = dDayCountdown();
    setDday(daysLeft);

  }, [applyEndDate, startDate, lectureId]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;

      setIsHidden(isScrolledToBottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <section className={`courseDetailWidgetSection ${isHidden ? 'widgetHidden' : ''}`}>
      <div className="courseDetailWidgetSection__inner">
        <div className="courseDetailWidgetSection__inner__textWrap">
          <p>수강 신청 마감일 {moment(applyEndDate).format("YYYY.MM.DD")} <span className="courseDetailWidgetSection-onMobHide">|</span> <br className="courseDetailWidgetSection-onMobShow" /> 학습시작일 {moment(startDate).format("YYYY.MM.DD")}</p>
        </div>
        <div className="courseDetailWidgetSection__inner__btnWrap">
          <div className="courseDetailWidgetSection__inner__btnWrap--dday">
            <h1>D{dday}</h1>
            <h2>마감일</h2>
          </div>
          <Link to={`/registration/${lectureId}`}
                onClick={(e) => {
                  e.preventDefault();
                  const currentDate = moment();
                  const targetDate = moment.tz(applyStartDate, 'Asia/Seoul');
                  const targetDate2 = moment.tz(applyEndDate, 'Asia/Seoul');
                  const dDiff = currentDate.diff(targetDate);
                  const dDiff2 = currentDate.diff(targetDate2, 'days');
                  if (dDiff < 0) {
                    alert("수강신청이 시작되지 않았습니다.");
                  } else if (dDiff2 > 0) {
                    alert("수강신청이 마감되었습니다.");
                  } else {
                    navigate(`/registration/${lectureId}`);
                  }
                }}>
            <div className="courseDetailWidgetSection__inner__btnWrap--application">수강신청하기</div>
          </Link>
        </div>
      </div>
    </section>
  )
}


export default CourseDetailWidget;