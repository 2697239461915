import "./faq.scss";
import {Accordion, AccordionDetails, AccordionSummary, Button, Typography} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useRecoilState, useRecoilValue, useRecoilValueLoadable} from "recoil";
import { defaultExpendedIdAtom, defaultExpendedCategoryAtom, isMiniNavOpenAtom } from "../../store/Atom";
import {boardFaqSelector, codeSelector, pageContentSelector} from "../../store/Selector";
import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import Pagination from "../../components/pagination/Pagination";

import enrollNone from "../../assets/images/faqImg/enroll_none.png";
import enrollHover from "../../assets/images/faqImg/enroll_hover.png";
import devCardNone from "../../assets/images/faqImg/devcard_none.png";
import devCardHover from "../../assets/images/faqImg/devcard_hover.png";
import scholarshipNone from "../../assets/images/faqImg/scholarship_none.png";
import scholarshipHover from "../../assets/images/faqImg/scholarship_hover.png";
import { Parser } from "html-to-react";

const useStyles = makeStyles({
  firstStyle:
    {
      width: "125px !important",
      backgroundImage: `url(${enrollNone})`,
      backgroundSize: "cover",
      '&:hover': {
        backgroundColor: "transparent !important",
        boxShadow: "none !important",
      }

    },
  secondStyle:
    {
      width: "161px !important",
      backgroundImage: `url(${devCardNone})`,
      backgroundSize: "cover",
      '&:hover': {
        backgroundColor: "transparent !important",
        boxShadow: "none !important",
      }

    },
  thirdStyle:
    {
      width: "125px !important",
      backgroundImage: `url(${scholarshipNone})`,
      backgroundSize: "cover",
      '&:hover': {
        backgroundColor: "transparent !important",
        boxShadow: "none !important",
      }

    },

  firstStyle2: {
    width: "125px !important",
    backgroundSize: "cover",
    backgroundImage: `url(${enrollHover})`,
    '&:hover': {
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    }
  },
  secondStyle2: {
    width: "161px !important",
    backgroundSize: "cover",
    backgroundImage: `url(${devCardHover})`,
    '&:hover': {
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    }
  },
  thirdStyle2: {
    width: "125px !important",
    backgroundSize: "cover",
    backgroundImage: `url(${scholarshipHover})`,
    '&:hover': {
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    }
  }


});

function Faq() {

  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("faq"));
  const [codes, setCodes] = useState([]);
  const codeDatas = useRecoilValueLoadable(codeSelector("SDS0015"));

  const [selectedCate, setSelectedCate] = useState("")
  //const [ filteredData, setFilteredData ] = useState(boardSummary[1].contents.filter((list => list.category === "수강신청")));
  //const [filteredData, setFilteredData] = useState([]);
  const [faqs, setFaqs] = useState([])
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const boardSummary = useRecoilValueLoadable(boardFaqSelector([selectedCate, page]));
  const [defaultExpendedId, setDefaultExpendedId] = useRecoilState(defaultExpendedIdAtom);
  const [defaultExpendedCategory, setDefaultExpendedCategory] = useRecoilState(defaultExpendedCategoryAtom);
  const isMiniNavOpen = useRecoilValue(isMiniNavOpenAtom);


  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  const requestFetchCodes = useCallback(() => {
    if (codeDatas === null || codeDatas === undefined) {
      return;
    }
    switch (codeDatas.state) {
      case 'hasValue':
        const result = codeDatas.contents;
        const contents = result.contents;
        setCodes(contents[0]);
        if (defaultExpendedId === "") {
          setSelectedCate(contents[0][0].codeValue);
        }
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw codeDatas.contents;
    }
  }, [setCodes, codeDatas]);

  const requestFetchFaq = useCallback(() => {
    if (boardSummary === null || boardSummary === undefined) {
      return;
    }
    switch (boardSummary.state) {
      case 'hasValue':
        const result = boardSummary.contents;
        const faqs = result.contents;
        setFaqs(faqs);
        setTotalPages(result.totalPages);
        //setFilteredData(faqs.filter((list => list.categoryName === "취업 훈련")));
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw boardSummary.contents;
    }
  }, [setFaqs, boardSummary]);


  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  useEffect(() => {
    requestFetchCodes();
  }, [requestFetchCodes]);

  useEffect(() => {
    requestFetchFaq();
  }, [requestFetchFaq, page, selectedCate]);


  const handleFilterCategory = async (newCategory) => {


    setDefaultExpendedId("");
    setSelectedCate(newCategory);


  }

  useEffect(() => {

    return (() => {
      setDefaultExpendedId("");
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (defaultExpendedId !== "") {
      setDefaultExpendedId(defaultExpendedId);
    }

    if (defaultExpendedCategory !== "") {
      //setDefaultExpendedCategory(defaultExpendedCategory);
      setSelectedCate(defaultExpendedCategory);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchUser = () => {
    console.log(`현재 페이지:${page}`);
  }

  const setCurrentPageNumber = (page) => {
    setPage(page);
  }

  const createMarkup = htmlString => ({ __html: htmlString });


  //
  const classes = useStyles();
  const buttonStep = [classes.firstStyle, classes.secondStyle, classes.thirdStyle];
  const buttonStepSelected = [classes.firstStyle2, classes.secondStyle2, classes.thirdStyle2];

  return (
      <main className="faqSection">
        {contents.map(list => (
          <PageThumbnail
              imgUrl={`/download/${list.faq_header_image}`}
              description={list.faq_title}
              miniNavProps={
                {
                  parentMenu: {
                    title: "훈련 센터",
                  },
                  childMenu: {
                    title: "FAQ",
                    menuId: 3,
                  }
                }
              }
          />
        ))}

        <section className="faqSection__boardWrap container">
          <div className="faqSection__boardWrap__titleWrap">
            <h1>FAQ</h1>
            <div className="faqSection__boardWrap__titleWrap--btnWrap">
              {codes.map((list, idx) => (
                  <Button
                      key={idx}
                      variant="contained"
                      className={selectedCate === list.codeValue ? buttonStepSelected[idx] : buttonStep[idx]}
                      onClick={() => handleFilterCategory(list.codeValue)}
                      sx={{zIndex: isMiniNavOpen ? -1 : 1}}
                  >
                    {/*{list.codeName}*/}
                  </Button>
              ))}


            </div>
          </div>

          <div className="faqSection__boardWrap__board">
            {faqs.map(list => (
                <React.Fragment key={list.id}>
                  <Accordion
                      className="faqSection__boardWrap--accordion"
                      onClick={() => {
                        if (defaultExpendedId === list.id) {
                          setDefaultExpendedId("");
                        } else {
                          setDefaultExpendedId(list.id)
                        }
                      }}
                      expanded={list.id === defaultExpendedId ? true : false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion__summary"
                    >
                      <Typography>
                          <span className="accordion__category">
                            <span style={{
                              fontFamily: "Noto Sans CJK KR",
                              color: "rgba(179,187,195,0.57 )",
                              marginRight: "10px"
                            }}>Q.</span>
                            [{list.categoryName}]
                          </span>
                        <span className="accordion__title">{list.title}</span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion__detailBox">
                        {/*<div style={{whiteSpace: "pre-wrap"}}>{list.answer}</div>*/}
                        <div dangerouslySetInnerHTML={createMarkup(list.answer)} />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </React.Fragment>
            ))}
          </div>
          <Pagination
              totalPages={totalPages}
              handleSearchUser={handleSearchUser}
              setCurrentPageNumber={setCurrentPageNumber}
          />
        </section>
      </main>

  )
}
export default Faq;