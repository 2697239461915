import "./oneToOne.scss";

import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";

import React, {useCallback, useEffect, useState} from "react";
import {pageContentSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";


function OneToOne() {
    const {
        register,
        handleSubmit ,
        formState: { isSubmitting, isSubmitted, errors}
    } = useForm();

    const navigate = useNavigate();
    const [contents, setContents] = useState([]);
    const contentsDatas = useRecoilValueLoadable(pageContentSelector("inquiry"));

    const requestFetchContents = useCallback(() => {
        if (contentsDatas === null || contentsDatas === undefined) {
            return;
        }
        switch (contentsDatas.state) {
            case 'hasValue':
                const result = contentsDatas.contents;
                const contents = result.contents;
                setContents(contents);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw contentsDatas.contents;
        }
    }, [setContents, contentsDatas]);

    useEffect(() => {
        requestFetchContents();
    }, [requestFetchContents]);

    const submitForm = (data) => {
        console.log("1:", data);
        const handphone = data.mobile1 + "-" + data.mobile2 + "-" + data.mobile3;
        data = { ...data, handphone};
        console.log("2:", data);
        const json = JSON.stringify(data);

        return fetch(`/api/inquiry/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: json,

        }).then((response) => {
            if (response.ok) {
                console.log("수신 성공");
                //console.log(response.json());
                alert("상담 신청이 완료되었습니다.");
                navigate("/inquiry");

            } else {
                console.error("Error 메시지를 확인하세요.")
            }
        })
    }

    return (
        <main className="oneToOneSection">
            {contents.map(list => (
                <PageThumbnail
                    imgUrl={`/download/${list.inquiry_header_image}`}
                    description={list.inquiry_title}
                    miniNavProps={
                        {
                            parentMenu: {
                                title: "훈련 센터",
                            },
                            childMenu: {
                                title: "문의 및 지원",
                                menuId: 3,
                            }
                        }
                    }
                />
            ))}
            <section className="oneToOneSection__contentsWrap container">
                <div className="oneToOneSection__contentsWrap__titleWrap">
                    <div className="oneToOneSection__contentsWrap__titleWrap__title">
                        <h3>1:1 문의 폼입니다</h3>
                    </div>
                </div>
                <div className="oneToOneSection__contentsWrap__registerForm">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="titleType10">
                            <span>상담신청</span>
                        </div>
                        <div className="viewType01 inputTb2 border photoUse marB_50">
                            <table>
                                <colgroup>
                                    <col id="title3" />
                                    <col />
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>성명 <span className="rq">*</span></th>
                                    <td>

                                        <input className="size01 gray" type="text" name="ireum_kor" readOnly="" id="ireum_kor"
                                               aria-invalid={isSubmitted ? (errors.uname ? "true" : "false") : undefined}
                                               {...register("uname", {
                                                   required: "한글성명은 필수 입력입니다."
                                               })} />
                                        {errors.uname && <small role="alert">{errors.uname.message}</small> }
                                    </td>

                                </tr>

                                <tr>
                                    <th>연락처 <span className="rq">*</span></th>
                                    <td>
                                        <input className="sel01" type="text" name="mobile1" id="mobile1" maxLength="3"
                                               aria-invalid={isSubmitted ? (errors.mobile1 ? "true" : "false") : undefined}
                                               {...register("mobile1", {
                                                   required: "핸드폰 번호1은 필수 입력입니다."
                                               })}/>
                                        <span className="dash2">-</span>
                                        <input className="sel01" type="text" name="mobile2" id="mobile2" maxLength="4"
                                               aria-invalid={isSubmitted ? (errors.mobile2 ? "true" : "false") : undefined}
                                               {...register("mobile2", {
                                                   required: "핸드폰 번호2는 필수 입력입니다."
                                               })}/>
                                        <span className="dash2">-</span>
                                        <input className="sel01" type="text" name="mobile3" id="mobile3" maxLength="4"
                                               aria-invalid={isSubmitted ? (errors.mobile3 ? "true" : "false") : undefined}
                                               {...register("mobile3", {
                                                   required: "핸드폰 번호3은 필수 입력입니다."
                                               })}/>
                                        {errors.mobile1 && <><br /><small role="alert">{errors.mobile1.message}</small></> }
                                        {errors.mobile2 && <><br /><small role="alert">{errors.mobile2.message}</small></> }
                                        {errors.mobile3 && <><br /><small role="alert">{errors.mobile3.message}</small></> }

                                    </td>
                                </tr>
                                <tr>
                                    <th>문의내용 <span className="rq">*</span></th>
                                    <td>
                                        <textarea name="add_record" id="add_record" className="txt_area" title="문의내용"
                                                  {...register("content", {
                                                      required: "문의내용은 필수 입력입니다.",
                                                      maxLength: {
                                                          value: 500,
                                                          message: "500자 이내로 입력바랍니다.",
                                                      },
                                                  })}
                                        ></textarea>
                                        {errors.content && <><br /><small role="alert">{errors.content.message}</small></>}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="agreeBase">
                            <div className="title">개인정보 수집 및 이용목적에 대한 동의(필수)</div>
                            <div className="textBox">
                                <ol>
                                    <li><strong>1. 개인정보의 수집·이용 목적</strong>

                                        <ul>
                                            <li>
                                                <p>신한 금융SW 아카데미는 교육상담, 지원자격 확인, 훈련생 선발, 합격후 학적생성 기초자료에 활용하며, 학적이 생성된 정보는 학적관리, 훈련비용지원, 출결관리, 성적관리, 증명발급, 취업알선, 만족도조사, 커뮤니티 활용 등을 목적으로 개인정보를 수집·이용합니다.</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><strong>2. 수집하려는 개인정보의 항목</strong>
                                        <ul>
                                            <li><strong>(필수)</strong>&nbsp;성명, 핸드폰</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>3. 개인정보의 보유 및 이용기간 : <span style={{fontSize: "14px"}}><u>3년</u></span></strong></p>


                                        <strong>4. 동의거부권리 및 불이익</strong>

                                        <ul>
                                            <li className="t1">개인정보 수집·이용에 대한 동의를 하지 않을 수 있으며, 동의하지 않으실 경우 상담요청처리 불가 등 불이익이 발생하게 됩니다.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            <div className="checkBoxWrap">
                                <div className="baseRadio">
                                    <input name="che_agree" id="che_agree" type="radio" value="agree"
                                           {...register("che_agree", {
                                               required: "개인정보 동의여부는 필수입력입니다.",
                                               validate: {
                                                   validateCheck: v =>
                                                       v !== "agree" ? "동의는 필수입니다" : true
                                               }
                                           })}
                                    /> <label htmlFor="che_agree">동의함</label>
                                </div>
                                <div className="baseRadio marL_16">
                                    <input name="che_agree" id="agree03" type="radio" value="disagree"
                                           {...register("che_agree", {
                                               required: "개인정보 동의여부는 필수입력입니다."
                                           })}
                                    /> <label htmlFor="agree03">동의하지 않음</label>
                                </div>
                                {errors.che_agree && <small role="alert">{errors.che_agree.message}</small> }
                            </div>

                        </div>

                        <div className="btnCenter">

                            <button className="btType01 blue" type="submit" disabled={isSubmitting}>상담신청</button>

                        </div>
                    </form>
                </div>

            </section>
        </main>
    )
}

export default OneToOne;