import "./governmentSupport.scss";
import React, {useCallback, useEffect, useState} from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import {pageContentSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";

function GovernmentSupport() {
  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("governmentSupport"));

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);
  return(
    <main className="governmentSection">
      {contents.map(list => (
        <PageThumbnail
          imgUrl={`/download/${list.governmentSupport_header_image}`}
          description={list.governmentSupport_title}
          miniNavProps={
            {
              parentMenu: {
                title: "사업 소개",
              },
              childMenu: {
                title: "국비지원제도",
                menuId: 0,
              }
            }
          }
        />
      ))}
      <section className="governmentSection__contentsWrap container">
        <div className="governmentSection__contentsWrap__titleWrap">
          <h1>국비지원 제도</h1>
        </div>
        <div className="governmentSection__contentsWrap">
          <div className="governmentSection__contentsWrap__primarySection">
            <img src={require("../../assets/images/governmentSupport-img-01.png")} alt="" />
            <div className="governmentSection__contentsWrap__primarySection--title">
              <div className="governmentSection__contentsWrap__primarySection--title__line"/>
              <h2>K-디지털 트레이닝</h2>
            </div>
            <div className="governmentSection__contentsWrap__primarySection--textBox">
              <p>
                K-디지털 트레이닝 사업은 디지털 신기술 인재 양성을 위해 <br  />
                혁신적 기술 및 훈련방법을 가진 기업-대학-민간 혁신기관을 선발하여 <br />
                학사 운영을 지원하고 수강생은 무료로 직업훈련을 받는 국비지원 사업입니다. <br />
                <br />
                AI, 빅데이터 등 디지털 신기술 분야에 다양하게 활용되는 기반 기술과 <br />
                이를 활용한 핀테크, 클라우드 등 응용기술을 배우고 <br />
                실전에서 활용할 수 있도록 다양한 훈련과정을 제공하고 있습니다.
              </p>
            </div>

            <div className="governmentSection__contentsWrap__primarySection--title">
              <div className="governmentSection__contentsWrap__primarySection--title__line"/>
              <h2>디지털선도기업 아카데미</h2>
            </div>
            <div className="governmentSection__contentsWrap__primarySection--textBox">
              <p>디지털 선도기업 아카데미는 K-디지털 트레이닝 사업의 프로그램 중 하나로 <br />
                 민관 파트너십을 구성해 신산업 분야의 우수 훈련 모델 확산을 지원하는 <br />
                 민관 협력 소프트웨어(SW) 인재 양성 프로그램입니다. <br />
                 <br />
                 디지털선도기업이 과정을 직접설계하고 기업 실무에 활용하는 자원과 <br />
                 새로운 훈련 방식을 접목하여 기업 맞춤형 전문 인재를 양성하고 있습니다.
              </p>
            </div>
          </div>

          <div className="governmentSection__contentsWrap__secondarySection">
            <img src={require("../../assets/images/governmentSupport-img-02.png")} alt=""/>
          </div>
        </div>
      </section>
    </main >
  )
}

export default GovernmentSupport;