import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import "./shareModal.scss";
import {lectureDetailSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";

function ShareModal({ isOpenShareModal, setIsOpenShareModal, lectureId}) {
  const pageUrl = "https://shinhanacademy.com/#/trainingDetail/" + lectureId;
  //console.log(pageUrl);
  const [ isCopySuccess, setIsCopySuccess ] = useState(false);

  const lectureData = useRecoilValueLoadable(lectureDetailSelector(lectureId));
  const [lecture, setLecture] = useState([]);

  const requestFetchLecture = useCallback(() => {
    if (lectureData === null || lectureData === undefined) {
      return;
    }
    switch (lectureData.state) {
      case 'hasValue':
        const result = lectureData.contents;
        const lectures = result.contents;
        setLecture(lectures[0]);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw lectureData.contents;
    }
  }, [setLecture, lectureData]);

  useEffect(() => {
    requestFetchLecture();
  }, [requestFetchLecture, lectureId]);

  const handleShare = (sns) => {

    if(sns === "kakao"){
      if(window.Kakao){
        const kakao = window.Kakao;
        if( !kakao.isInitialized()){
          kakao.init(process.env.REACT_APP_KAKO_API_KEY);
        }

      kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: lecture.title,
          description: '신한 금융SW 아카데미',
          imageUrl: lecture.thumbnailUrl,
          link: {
            webUrl : pageUrl,
            mobileWebUrl : pageUrl,
          },
        },
        buttons: [
          {
            title: '웹으로 이동',
            link: {
              webUrl : pageUrl,
              mobileWebUrl : pageUrl,
            },
          },
        ]
      });
    };

    } else if(sns === "naver"){
      const encordedUrl = encodeURI(encodeURIComponent(pageUrl));
      const encordedTtile = encodeURI(lecture.title);
      const shareURL = "https://share.naver.com/web/shareView?url=" + encordedUrl + "&title=" + encordedTtile;
      window.open(shareURL);
      
    } else if(sns === "twitter"){
      window.open(`https://twitter.com/intent/tweet?url=${pageUrl}`);
    
    } else if (sns === "faceBook"){
      window.open(`http://www.facebook.com/sharer/sharer.php?u=${pageUrl}`);
    
    } else if (sns === "copy"){
      navigator.clipboard.writeText(pageUrl);
      setIsCopySuccess(true);

      setTimeout(() => {
        setIsCopySuccess(false)
      }, 3000);
    }

  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  return (
    <Dialog onClose={() => setIsOpenShareModal(false)} open={isOpenShareModal}>
      <DialogTitle className="shareModal__titleWrap">
        <h1 className="shareModal__titleWrap--title">공유하기</h1>
        <img onClick={() => setIsOpenShareModal(false)} className="shareModal__titleWrap--titleImg" src={require("../../assets/icons/ico-mini-close.png")} alt=""/>
      </DialogTitle>
      <DialogContent className="shareModal__contentsWrap">
        <div className="shareModal__contentsWrap--snsBoxWrap">
          <div className="shareModal__contentsWrap--snsBoxWrap--snsBox">
            <img 
              src={require("../../assets/icons/ico-kakao.png")} alt=""
              onClick={() => handleShare("kakao")}
              />
            <span>카카오</span>
          </div>
          <div className="shareModal__contentsWrap--snsBoxWrap--snsBox">
            <img 
              src={require("../../assets/icons/ico-naver.png")} alt=""
              onClick={() => handleShare("naver")}
              />
            <span>네이버</span>
          </div>
          <div className="shareModal__contentsWrap--snsBoxWrap--snsBox">
            <img 
              src={require("../../assets/icons/ico-twitter.png")} alt=""
              onClick={() => handleShare("twitter")}
              />
            <span>트위터</span>
          </div>
          <div className="shareModal__contentsWrap--snsBoxWrap--snsBox">
            <img 
              src={require("../../assets/icons/ico-facebook.png")} alt=""
              onClick={() => handleShare("faceBook")}
              />
            <span>페이스북</span>
          </div>
        </div>
        <div className="shareModal__contentsWrap--clipboardWrap">
          <input value={pageUrl} disabled/>
          <div 
            onClick={() => handleShare("copy")} 
            className="shareModal__contentsWrap--clipboardWrap__copyBtn">
              {isCopySuccess ? 
                <img style={{width: 20}}  src={require("../../assets/icons/ico-check.png")} alt=""/>
                :
                <img style={{width: 20}} src={require("../../assets/icons/ico-copy.png")} alt=""/>
              }
            </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ShareModal;