import "./home.scss";
import React, {Suspense, useCallback, useEffect, useRef, useState} from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import BoardSummary from "../../components/boardSummary/BoardSummary";
import VideoDialog from "../../components/videoDialog/VideoDialog";
import {isVideoDialogOpenAtom} from "../../store/Atom";
import {boardSummarySelector, pageContentSelector, recentStorySelector} from "../../store/Selector";
import { AOS_DURATION } from "../../common/hleper";
import { Link } from "react-router-dom";
import VideoMsgBox from "../../components/videoMsgBox/VideoMsgBox";

function Home() {

  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("homepage"));
  const boardSummaryData = useRecoilValueLoadable(boardSummarySelector);
  const recentStoryData = useRecoilValueLoadable(recentStorySelector);
  const [videoOpen, setVideoOpen] = useRecoilState(isVideoDialogOpenAtom);
  const sectionRef = useRef(null);
  const body = document.body;
  const [recentStories, setRecentStories] = useState([]);
  const [recentSummaries, setRecentSummaries] = useState([]);

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  const requestFetchSummaries = useCallback(() => {
    //console.log("1", boardSummaryData);
    if (boardSummaryData === null || boardSummaryData === undefined) {
      return;
    }
    switch (boardSummaryData.state) {
      case 'hasValue':
        const result = boardSummaryData.contents;
        //console.log("2", result);
        //const summaries = result.contents;
        setRecentSummaries(result);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw boardSummaryData.contents;
    }
  }, [recentSummaries, boardSummaryData]);

  const requestFetchStory = useCallback(() => {
    if (recentStoryData === null || recentStoryData === undefined) {
      return;
    }
    switch (recentStoryData.state) {
      case 'hasValue':
        const result = recentStoryData.contents;
        const stories = result.contents;
        setRecentStories(stories);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw recentStoryData.contents;
    }
  }, [setRecentStories, recentStoryData]);

  const scrollDown = () => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const videoOpenfn = (url) => {
    setVideoOpen({
      isOpen: true,
      url
    })
  }

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  useEffect(() => {
    requestFetchSummaries();
  }, [requestFetchSummaries]);

  useEffect(() => {
    requestFetchStory();
  }, [requestFetchStory]);


  useEffect(() => {
    if(videoOpen.isOpen){
      body.style.overflow = "hidden"
    }

    return () => {
      body.style.overflowY = "scroll"
    }

  }, [body.style, videoOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <main className="homeSection">
      
      {/* Video Section */}

        <section className="videoWrap">
          {contents.map((list, index) => (
              (list.home_main_video &&
            <video
              key={index}
              src={`/video/${list.home_main_video}`}
              controls={false}
              autoPlay
              muted
              loop
              playsInline
             />) || (list.home_main_image &&
            <img key={index} src={`/download/${list.home_main_image}`} alt="메인 이미지"/>)


          ))}
        </section>
        <div className="videoWrap--scrollIco" onClick={() => scrollDown()}>
          <h1>Scroll</h1>
          <img src={require("../../assets/icons/ico-mouse.png")} alt="" />
        </div>


      {/* Primary Section */}
      <section className="primarySection" ref={sectionRef}>
        <div className="primarySection__inner">
          <div 
            className="primarySection__contentsBox" 
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration={AOS_DURATION}
            data-aos-once="true"
          >
            <h1>훈련과정</h1>
            <h2>
              K-디지털 트레이닝 <br className="onMobShow" /> 
              '디지털 선도기업 아카데미'
            </h2>
            <h3>
              신한DS 금융SW 아카데미 <br />
              풀스택 개발자 양성과정
            </h3>
            <p>
              본 교육과정은 고용노동부가 주최하고 디지털선도기업인 (주)신한DS와 <br />
              전문교육기관인 대한상공회의소가 주관하여 공동 운영합니다.
            </p>
            <Link to={"/trainingCourse/01"}>
              <div className="viewMoreBtn">
                view more +
                <div className="underArrow" />
              </div>
            </Link>
          </div>
          <div className="primarySection__relativeBox">
            <div className="primarySection__partnersWrap">
              <div 
                className="primarySection__partnersWrap--logoBox"
                data-aos="fade-up"
                data-aos-delay="800"
                data-aos-duration={AOS_DURATION}
                data-aos-once="true"
              >
                <img src={require("../../assets/icons/logo-partners-01.svg").default} alt="신한DS" />
              </div>
              <div 
                className="primarySection__partnersWrap--logoBox"
                data-aos="fade-up"
                data-aos-delay="1000"
                data-aos-duration={AOS_DURATION}
                data-aos-once="true"
              >
                <img src={require("../../assets/icons/logo-partners-02.svg").default} alt="고용노동부" />
              </div>
              <div 
                className="primarySection__partnersWrap--logoBox"
                data-aos="fade-up"
                data-aos-delay="1200"
                data-aos-duration={AOS_DURATION}
                data-aos-once="true"
                >
                <img src={require("../../assets/icons/logo-partners-03.svg").default} alt="대한상공회의소" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Secondary Section 공지사항, FAQ */}
      <section className="secondarySection">
        <div 
          className="secondarySection__boardWrap"
          data-aos="fade-up"
          data-aos-delay="800"
          data-aos-duration={AOS_DURATION}
          data-aos-once="true"
        >
          {recentSummaries.map((list, index) => (
              <BoardSummary
                  key={index}
                  boardSummaryData={list}/>
          ))}

          {/*<BoardSummary boardSummaryData={recentSummaries[1]}/>*/}


        </div>
      </section>
      
      {/* Tertiary section */}
      <section className="tertiarySection">
        <div className="tertiarySection__contentsBox">
          <div 
            className="tertiarySection__contentsBox--text"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration={AOS_DURATION}
            data-aos-once="true"
          >
            <h1>STORY</h1>
            <h2><span>우리들의</span><span>이야기</span></h2>
            <p>#소통해요 #유튜브 #SNS</p>
            <Link to={"/videoMsg"}>
              <div className="viewMoreBtn">
                view more +
              </div>
            </Link>
          </div>
          <div className="academySection__contentsWrap__videoGrid">
            <Suspense fallback={<div>로딩중입니다...</div>}>
              {recentStories.map(list => (
                  <React.Fragment key={list.id}>
                    <VideoMsgBox data={list} videoOpenfn={videoOpenfn}/>
                  </React.Fragment>
              ))}
              {recentStories.length === 0 &&
                  <>
                    <div />
                    <h1 className="academySection__contentsWrap__noSearch">조회된 스토리가 없습니다.</h1>
                    <div />
                  </>
              }
            </Suspense>
          </div>

        </div>
      </section>
      <VideoDialog />
    </main>

  )
}

export default Home;