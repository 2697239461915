import "./footer.scss";
import {useNavigate} from "react-router-dom";
function Footer () {
  const navigate = useNavigate();
  return (
    <footer className="footerSection">
      <div className="footerSection__innerBox">
        <div className="footerSection__innerBox--contactWrap">
          <div className="footerSection__innerBox--contactWrap-inner">
            <p>
              <div>상호 : (주)퍼스트존 │ 대표 : 신철우</div>

              <div><span>개인정보관리책임자 : 신철우</span> <span className="brn">사업자 등록번호 : 260-87-01655</span></div>
              <div>통신판매 : 2021-서울마포-0387호</div>
            </p>

          </div>
        </div>
        <div className="footerSection__innerBox--contactWrap">
          <div className="footerSection__innerBox--contactWrap-inner">
            <p>
              <div>주소 : 서울특별시 마포구 월드컵북로 4길 77 (1층,6층)</div>
              <div>TEL : 02-6392-0044</div>
              <div><span>상담시간 : 평일 10:00 ~ 17:00</span> <span className="lunchTime">(점심시간 13:00 ~ 14:00)</span></div>
            </p>

          </div>
        </div>
        <div className="footerSection__innerBox--copyrightWrap">
          <p>Copyright &copy; Shinhan DS All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;