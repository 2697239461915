import "./registration.scss";

import React, {useCallback, useEffect, useState} from "react";
import {lectureDetailSelector, pageContentSelector, lectureMemberDetailSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";
import {useParams, useNavigate} from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import DetailThumbnail from "../../components/detailThumbnail/DetailThumbnail";




function RegistrationShow() {




    let params = useParams();
    const navigate = useNavigate();
    const [contents, setContents] = useState([]);
    const contentsDatas = useRecoilValueLoadable(pageContentSelector("trainingDetail"));

    const lectureData = useRecoilValueLoadable(lectureDetailSelector(params?.lectureId));
    const lectureMemberData = useRecoilValueLoadable(lectureMemberDetailSelector(params?.memberId));
    const [lecture, setLecture] = useState([]);
    const [member, setMember] = useState([]);
    const [juso, setJuso] = useState("");




    const requestFetchContents = useCallback(() => {
        if (contentsDatas === null || contentsDatas === undefined) {
            return;
        }
        switch (contentsDatas.state) {
            case 'hasValue':
                const result = contentsDatas.contents;
                const contents = result.contents;
                setContents(contents);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw contentsDatas.contents;
        }
    }, [setContents, contentsDatas]);

    const requestFetchLecture = useCallback(() => {
        if (lectureData === null || lectureData === undefined) {
            return;
        }
        switch (lectureData.state) {
            case 'hasValue':
                const result = lectureData.contents;
                const lectures = result.contents;
                //console.log(lectures);
                setLecture(lectures[0]);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw lectureData.contents;
        }
    }, [setLecture, lectureData]);

    const requestFetchLectureMember = useCallback(() => {
        if (lectureMemberData === null || lectureMemberData === undefined) {
            return;
        }
        switch (lectureMemberData.state) {
            case 'hasValue':
                const result = lectureMemberData.contents;
                const members = result.contents;
                console.log(members);
                const session_uid = sessionStorage.getItem("uid");
                console.log("session uid=" + session_uid);
                //console.log("member uid=" + member.uid);
                if (session_uid === null) {
                    alert("접수내역을 확인해 주십시오");
                    navigate('/registrationCheck/' + params?.lectureId);
                } else {

                    if (members.length === 0 || session_uid !== members[0].uid) {
                        alert("본인의 접수내역만 확인가능합니다.");
                        navigate('/registrationCheck/' + params?.lectureId);
                    } else {
                        setMember(members[0]);
                    }
                }
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw lectureMemberData.contents;
        }
    }, [setMember, lectureMemberData, params?.lectureId, navigate]);




    useEffect(() => {
        requestFetchContents();
    }, [requestFetchContents]);

    useEffect(() => {
        requestFetchLecture();
    }, [requestFetchLecture, params?.lectureId]);

    useEffect(() => {
        requestFetchLectureMember();
    }, [requestFetchLectureMember, params?.lectureId, params?.memberId]);

    useEffect(() => {
        if (member.length !== 0) {
            setJuso(member.address.replaceAll("|", " "));
        }
    }, [member]);





    return(
        <main className="registrationSection">
            {contents.map((list, idx) => (
                <DetailThumbnail
                    key={lecture.id}
                    defaultImgUrl={`/download/${list.trainingDetail_header_image}`}
                    imgUrl={lecture.backgroundUrl}
                    lecture={lecture}
                    miniNavProps={
                        {
                            parentMenu: {
                                title: "훈련 과정",
                            },
                            childMenu: {
                                title: lecture.lectureType === '01' ? "취업 훈련" : (lecture.lectureType === '02' ? "직무 훈련" : "원격 훈련"),
                                menuId: 1,
                            }
                        }
                    }
                />
            ))}
            <section className="registrationSection__contentsWrap container">
                <div className="registrationSection__contentsWrap__titleWrap">
                    <div className="registrationSection__contentsWrap__titleWrap__title">
                        <h1>{lecture.title}</h1>
                        <h3>수강신청 확인 화면입니다</h3>
                    </div>

                </div>
                <div className="registrationSection__contentsWrap__registerForm">
                    <div className="titleType10">
                        <span>지원자 정보</span>
                    </div>
                    <div className="viewType01 inputTb2 border photoUse marB_50">
                        <table>
                            <colgroup>
                                <col id="title" />
                                <col />
                                <col id="title2"/>
                                <col />
                            </colgroup>
                            <tbody>
                            <tr>
                                <th>한글성명 </th>
                                <td>

                                    {member.uname}
                                </td>
                                <th>생년월일,성별 </th>
                                <td>

                                    {moment(member.birthday).format('YYYY-MM-DD')}

                                    &nbsp;
                                    ({Math.floor(moment().diff(moment(member.birthday), 'years', true))}세)
                                    ,
                                    &nbsp;

                                    {member.gender === '01' ? '남' : '여'}
                                    &nbsp;
                                    {member.isForeigner ? '외국인' : ''}
                                </td>
                            </tr>

                            <tr>
                                <th>핸드폰 </th>
                                <td colSpan="3">
                                    {member.handphone}

                                </td>
                            </tr>

                            <tr>
                                <th>추가연락처</th>
                                <td colSpan="3">
                                    {member.handphone2}
                                </td>
                            </tr>


                            <tr>
                                <th>이메일 </th>
                                <td colSpan="3">
                                    {member.email}
                                </td>
                            </tr>
                            <tr>
                                <th>현주소 </th>
                                <td colSpan="3">
                                    {juso}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className="photoPop">
                            <div className="in">

                                <div className="img" id="picImg">
                                    <img alt="반명함판 사진" style={{width: "158px",  height: "211px"}}
                                         src={member.thumbnailImage} />
                                </div>


                            </div>
                        </div>
                    </div>

                    {(lecture.lectureType === '01' && <><div className="titleType10">
                        <span>학력 사항</span>
                    </div>
                        <div className="viewType01 inputTb2 border marB_50">
                            <table>
                                <colgroup>
                                    <col id="title" />
                                    <col />
                                </colgroup>

                                <tbody>
                                <tr>
                                    <th>최종학력 구분 </th>
                                    <td colSpan="3">
                                        {member.finalEducationName} &nbsp;
                                        {member.schoolName} &nbsp;
                                        {member.major} &nbsp;
                                        {member.graduationDate && <>졸업년월: {moment(member.graduationDate).format('YYYY-MM')}</>}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="titleType10">
                            <span>기타입력 사항</span>
                        </div>
                        <div className="viewType01 inputTb2 border marB_50">
                            <table>
                                <colgroup>
                                    <col id="title" />
                                    <col />
                                    <col id="title2" />
                                    <col />
                                </colgroup>
                                <tbody>

                                <tr>
                                    <th>지원동기 </th>
                                    <td colSpan="3">
                                        {member.donggiName}
                                    </td>
                                </tr>

                                <tr>
                                    <th>취업이력 </th>
                                    <td>
                                        {member.jobHistoryName}
                                    </td>
                                    <th>실직기간 </th>
                                    <td>
                                        {member.unemployeementPeriodName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>우선선발</th>
                                    <td>
                                        {member.prioritySelectionName}
                                    </td>
                                    <th>병역관계 </th>
                                    <td>
                                        {member.militaryName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>최초 정보습득경로 </th>
                                    <td colspan="3">
                                        {member.accuisitionPathName}
                                    </td>
                                </tr>

                                <tr>
                                    <th>관련사항</th>
                                    <td colSpan="3">
                                        {
                                           member.bigo !== undefined  &&  member.bigo.split("\n").map((item, idx) => {
                                                    return (
                                                        <span key={idx}>
                                                            {item}
                                                            <br/>
                                                        </span>
                                                    )
                                                })


                                        }
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div></>) || <><div className="titleType10">
                        <span>재직정보 입력사항</span>
                    </div>
                        <div className="viewType01 inputTb2 border marB_50">
                            <table>
                                <colgroup>
                                    <col id="title" />
                                    <col />
                                </colgroup>

                                <tbody>
                                <tr>
                                    <th>회사명 </th>
                                    <td>
                                        {member.companyname}

                                    </td>
                                </tr>
                                <tr>
                                    <th>직장전화번호 </th>
                                    <td>
                                        {member.jiktel}

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </>}




                    <div className="btnCenter">

                        <button className="btType01 blue" onClick={() => {
                            navigate("/registrationEdit/" + lecture.id + "/" + member.id);
                        }}>수정하기</button>

                    </div>
                </div>
            </section>
        </main>
    )
}

export default RegistrationShow;