import "./videoMsg.scss";
import React, {useCallback, useEffect, useState} from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import { FormControl, Input, InputAdornment, MenuItem, Select } from "@mui/material";
import VideoMsgBox from "../../components/videoMsgBox/VideoMsgBox";
import {useRecoilState, useRecoilValue, useRecoilValueLoadable} from "recoil";
import { isMiniNavOpenAtom, isVideoDialogOpenAtom } from "../../store/Atom";
import {dsStorySelector, dsStoryTagSelector, pageContentSelector} from "../../store/Selector";
import VideoDialog from "../../components/videoDialog/VideoDialog";
import Pagination from "../../components/pagination/Pagination";

function VideoMsg() {
  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("videoMsg"));
  const dsTags = useRecoilValueLoadable(dsStoryTagSelector);
  const [ selectedTag, setSelectedTag ] = useState("all");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [ searchedKeyword, setSearchedKeyword ] = useState("");
  //const [ filteredVideoData, setFilteredVideoData ] = useState([]);
  const [stories, setStories] = useState([]);
  const [tags, setTags] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const videoMsgDatas = useRecoilValueLoadable(dsStorySelector([selectedTag, selectedTitle, page]));
  const isMiniNavOpen = useRecoilValue(isMiniNavOpenAtom);
  const [videoOpen, setVideoOpen] = useRecoilState(isVideoDialogOpenAtom);
  const body = document.body;

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  const requestFetchStory = useCallback(() => {
    if (videoMsgDatas === null || videoMsgDatas === undefined) {
      return;
    }
    switch (videoMsgDatas.state) {
      case 'hasValue':
        const result = videoMsgDatas.contents;
        const stories = result.contents;
        setStories(stories);
        setTotalPages(result.totalPages);
        //setFilteredVideoData(stories);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw videoMsgDatas.contents;
    }
  }, [setStories, videoMsgDatas]);

  const requestFetchTag = useCallback(() => {
    if (dsTags === null || dsTags === undefined) {
      return;
    }

    switch (dsTags.state) {
      case 'hasValue':
        const result = dsTags.contents;
        const tags = result.contents;

        //console.log("tags==>", tags);
        setTags(tags);
        break;
    }
  }, [setTags, dsTags]);

  const handleChange = (e) => {
    const targetTag = e.target.value;
    setSelectedTag(targetTag);

    if(targetTag === "all"){
      //setFilteredVideoData(stories);
    } else {
      // const filteredData = stories.filter(list => list.tags.includes(targetTag));
      // setFilteredVideoData(filteredData);
    }
  } 
  const handleSearch = (e) => {
    const keyword = e.target.value;
    setSearchedKeyword(keyword);
    setSelectedTitle(keyword);

  }

  const videoOpenfn = (url) => {
    setVideoOpen({
      isOpen: true,
      url
    })
  }

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  useEffect(() => {
    requestFetchStory();
  }, [requestFetchStory, page, selectedTag, selectedTitle]);

  useEffect(() => {
    requestFetchTag();
  }, [requestFetchTag]);

  useEffect(() => {
    if(videoOpen.isOpen){
      body.style.overflow = "hidden"
    }

    return () => {
      body.style.overflowY = "scroll"
    }

  }, [body.style, videoOpen]);

  useEffect(() => {
      setSearchedKeyword("");
  }, [selectedTag])

  const handleSearchUser = () => {
    console.log(`현재 페이지:${page}`);
  }

  const setCurrentPageNumber = (page) => {
    setPage(page);
  }

  return(
    <main className="videoMsgSection">
      {contents.map((list, index) => (
        <PageThumbnail
          key={index}
          imgUrl={`/download/${list.videoMsg_header_image}`}
          description={list.videoMsg_title}
          miniNavProps={
            {
              parentMenu: {
                title: "STORY",
              },
              childMenu: {
                title: "신한DS",
                menuId: 2,
              }
            }
          }
        />
      ))}

      <section className="videoMsgSection__contentsWrap container">
        <div className="videoMsgSection__contentsWrap__titleWrap">
          <div className="academySection__contentsWrap__titleWrap__title">
            <h1>신한DS</h1>
            <h3>신한DS의 사람, 문화, 생활 그 모든 이야기</h3>
          </div>
          <div className="videoMsgSection__contentsWrap__titleWrap--searchWrap">
            <FormControl variant="standard" sx={{mr: 3, minWidth: window.innerWidth <= 600 ? 0 : 120 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedTag}
                onChange={handleChange}
                defaultValue={"all"}
                className="videoMsgSection__selectInput"
                sx={{zIndex: isMiniNavOpen ? -1 : 1}}
              >
                <MenuItem value="all" >
                  <em>전체</em>
                </MenuItem>
                {tags.map((tag) => (
                    <MenuItem value={tag}>{tag}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Input
              variant="standard" 
              placeholder="키워드를 검색해 보세요." 
              endAdornment={
              <InputAdornment position="end">
                <img style={{width: 15}} src={require("../../assets/icons/ico-glass.png")} alt=""/>
              </InputAdornment>}
              onChange={(e) => handleSearch(e)}
              value={searchedKeyword}
              className="videoMsgSection__searchInput"
              sx={{zIndex: isMiniNavOpen ? -1 : 1}}
              />
          </div>
        </div>

        <div className="videoMsgSection__contentsWrap__showMoreBtn">
          <span onClick={() => window.open("https://www.youtube.com/@ds_3841/videos")}>다양한 컨텐츠 확인하기+</span>
        </div>
        
        <div className="videoMsgSection__contentsWrap__videoGrid">
          {stories.map(list => (
            <React.Fragment key={list.id}>
              <VideoMsgBox data={list} videoOpenfn={videoOpenfn}/>
            </React.Fragment>
          ))}
          {stories.length === 0 &&
            <>
              <div />
              <h1 className="videoMsgSection__contentsWrap__noSearch">조회된 영상이 없습니다.</h1>
              <div />
            </>
          }
        </div>

        <Pagination
            totalPages={totalPages}
            handleSearchUser={handleSearchUser}
            setCurrentPageNumber={setCurrentPageNumber}
        />
      </section>

      {/* common video modal */}
      <VideoDialog />
    </main >
  )
}

export default VideoMsg;