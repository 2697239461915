import { useRef } from "react";
import "../../pages/TrainingCourse/trainingDetail.scss";

function QualificationItem ({ title, contents }) {
  const lineRef = useRef(null);

  return(
    <div 
      className="detailSection--contentsWrap__infoWrap--eachSection--qualificationWrap--itemBox"
      onMouseOver={() => lineRef.current.classList.add("hoverPoint")}
      onMouseLeave={() => lineRef.current.classList.remove("hoverPoint")}
    >
      <div className="itemBox--line" ref={lineRef} />
      <h1>{title}</h1>
      {contents}
    </div>
  )
}

export default QualificationItem;