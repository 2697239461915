import React, {useCallback, useEffect, useRef} from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValueLoadable } from "recoil";
import MobNav from "./mobNav/MobNav";
import { isDialogOpenAtom } from "../../store/Atom";
import { pageMenuSelector, codeSelector } from "../../store/Selector";
import "./header.scss";

function Header () {
  const [menus, setMenus] = useState([]);
  const menuData = useRecoilValueLoadable(pageMenuSelector);

  const [codes, setCodes] = useState([]);
  const codeDatas = useRecoilValueLoadable(codeSelector("SDS0001"));

  const [scrollDown, setScrollDown] = useState(false);
  const setIsDialogOpen = useSetRecoilState(isDialogOpenAtom);
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const location = useLocation();
  const menuCategoryRef = useRef(null);
  const menuItemRef = useRef(null);


  const requestFetchMenus = useCallback(() => {
    if (menuData === null || menuData === undefined) {
      return;
    }
    switch (menuData.state) {
      case 'hasValue':
        const result = menuData.contents;
        const contents = result.contents;
        setMenus(contents[0].subPages);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw menuData.contents;
    }
  }, [setMenus, menuData]);

  const requestFetchCodes = useCallback(() => {
    if (codeDatas === null || codeDatas === undefined) {
      return;
    }
    switch (codeDatas.state) {
      case 'hasValue':
        const result = codeDatas.contents;
        const contents = result.contents;
        setCodes(contents[0]);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw codeDatas.contents;
    }
  }, [setCodes, codeDatas]);

  useEffect(() => {
    requestFetchMenus();
  }, [requestFetchMenus]);

  useEffect(() => {
    requestFetchCodes();
  }, [requestFetchCodes]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrollDown(scrollPosition > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if(isMenuHovered){
      menuCategoryRef.current.classList.add('menuSlide');
      menuItemRef.current.classList.add('menuItemFade');
    } else {
      menuCategoryRef.current.classList.remove('menuSlide');
      menuItemRef.current.classList.remove('menuItemFade');
    } 

  }, [isMenuHovered]);


  return (
    <div className="headerSection__outer">
      <header 
        className="headerSection" 
        style={{
          backgroundColor: scrollDown ? "rgba(0, 0, 0, 0.8)" : isMenuHovered ? "rgba(0, 0, 0, 0.8)" : "transparent",
          transitionDuration: "0.3s"
          }}>
        <div className="headerSection__inner" >
          <div className="headerSection__logoWrap">
            <Link to={"/"}>
              <img src={require("../../assets/icons/logo-shinhanDS-new.png")} alt="신한 금융SW 아카데미"></img>
            </Link>
          </div>
          <div className="headerSection__navWrap onMobHide">
            <ul onMouseOver={() => setIsMenuHovered(true)}>
              <Link to={"/introduction"}>
                <li>
                  사업 소개
                  <div 
                    className={
                      `selectLine ${(
                        (
                          location.pathname === "/introduction" || 
                          location.pathname === "/governmentSupport"  
                        )
                        && isMenuHovered) && "showSelectedLine"}`
                    }
                  />
                </li>
              </Link>
              <Link to={"/trainingCourse"}>
                <li>
                  훈련 과정
                  <div className={`selectLine ${(location.pathname === "/trainingCourse" && isMenuHovered) && "showSelectedLine"}`}/>
                </li>
              </Link>
              <Link to={"/academy"}>
                <li>
                  STORY
                  <div
                    className={
                      `selectLine ${(
                        (
                          location.pathname === "/academy" ||
                          location.pathname === "/videoMsg"
                        )
                        && isMenuHovered) && "showSelectedLine"}`} 
                        style={{left: "-20px"}
                      }
                    />
                </li>
              </Link>
              <Link to={"/notice"}>
                <li>
                  훈련 센터
                  <div 
                    className={
                      `selectLine ${(
                        (
                          location.pathname === "/notice" ||
                          location.pathname === "/faq" ||
                          location.pathname === "/inquiry" ||
                          location.pathname === "/archive" ||
                          location.pathname === "/trainingFacility"
                        ) 
                        && isMenuHovered) && "showSelectedLine"}`
                    } 
                  />
                </li>
              </Link>
            </ul>
          
          </div>

          {/* Moblie menu section*/}
          <div 
            className="headerSection__mobNavWrap onMobShow"
            onClick={() => setIsDialogOpen(true)}
          >
            <img src={require("../../assets/icons/ico-menu.png")} alt=""/>
          </div>
        </div>
      
        <MobNav />
      </header>
        <div 
          className="headerSection--dropDownMenu" 
          ref={menuCategoryRef}
          onMouseLeave={() => setIsMenuHovered(false)}
        >
          <div className="headerSection--dropDownMenu__inner" ref={menuItemRef}>
            <div className="headerSection--dropDownMenu__inner--temp" />
            <div className="headerSection--dropDownMenu__inner--navWrap">
              {menus.map((list, index) => (
                  <ul key={index}>
                    {index !== 1 && list.subPages.map((s, i) => {
                      if (!s.isHidden)
                        return <Link to={s.url} key={i}>
                                <li className={location.pathname === `/${s.url}` ? "selectedHeaderMenu" : null}>{s.title}</li>
                              </Link>
                      return <></>
                    })}

                    {index === 1 && codes.map((list, idx) => (
                        <Link key={idx} to={`/trainingCourse/${list.codeValue}`}>
                          <li className={location.pathname === `/trainingCourse/${list.codeValue}` ? "selectedHeaderMenu" : null}>{list.codeName}</li>
                        </Link>
                    ))}
                  </ul>
              ))}
            </div>
          </div>
        </div>      
    </div>
  )
}

export default Header;