import { Route, Routes, HashRouter } from "react-router-dom";
import Home from "./Home/Home";
import "../common/css/common.scss"
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import Notice from "./Notice/Notice";
import Faq from "./Faq/Faq";
import TrainingCourse from "./TrainingCourse/TrainingCourse";
import TrainingFacility from "./TrainingFacility/TrainingFacility";
import Introduction from "./Introduction/Introduction";
import GovernmentSupport from "./GovernmentSupport/GovernmentSupport";
import Academy from "./Academy/Academy";
import VideoMsg from "./VideoMsg/VideoMsg";
import Blog from "./Blog/Blog";
import BlogDetail from "./Blog/BlogDetail";
import Inquiry from "./Inquiry/Inquiry";
import TrainingDetail from "./TrainingCourse/TrainingDetail";
import Registration from "./Registration/Registration";
import RegistrationCheck from "./Registration/RegistrationCheck";
import RegistrationShow from "./Registration/RegistrationShow";
import RegistrationEdit from "./Registration/RegistrationEdit";
import Archive from "./Archive/Archive";
import OneToOne from "./Inquiry/OneToOne";

function Router() {
  return (
    <HashRouter >
      <Header />
      <Routes>
        {/* randing page */}
        <Route path="/" element={<Home />} />

        {/* 사업소개 */}
        <Route path="/introduction" element={<Introduction />} />
        <Route path="/governmentSupport" element={<GovernmentSupport />} />

        {/* 훈련과정 */}
        <Route path="/trainingCourse/:lectureType" element={<TrainingCourse />} />
        <Route path="/trainingCourse" element={<TrainingCourse />} />
        <Route path="/trainingDetail/:lectureId" element={<TrainingDetail />} />
        <Route path="/registration/:lectureId" element={<Registration />} />
        <Route path="/registrationCheck/:lectureId" element={<RegistrationCheck />} />
        <Route path="/registrationShow/:lectureId/:memberId" element={<RegistrationShow />} />
        <Route path="/registrationEdit/:lectureId/:memberId" element={<RegistrationEdit />} />


        {/* STORY */}
        <Route path="/academy" element={<Academy />} />
        <Route path="/videoMsg" element={<VideoMsg />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogDetail/:blogId" element={<BlogDetail />} />

        {/* 훈련센터 */}
        <Route path="/notice" element={<Notice />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/inquiry" element={<Inquiry />} />
        <Route path="/oneToOne" element={<OneToOne />} />
        <Route path="/archive" element={<Archive />} />
        <Route path="/trainingFacility" element={<TrainingFacility />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default Router;
