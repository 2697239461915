import { Link } from "react-router-dom";
import "./boardSummary.scss";
import { useSetRecoilState } from "recoil";
import { defaultExpendedIdAtom, defaultExpendedCategoryAtom } from "../../store/Atom";


function BoardSummary({ boardSummaryData }) {
  const setDefaultExpendedId = useSetRecoilState(defaultExpendedIdAtom);
  const setDefaultExpendedCategory = useSetRecoilState(defaultExpendedCategoryAtom);


  return(
    <section className="boardSummarySection">
      <h3>
        <img src={require(`../../assets/icons/${boardSummaryData.icon}`)} alt="" />
        <Link to={boardSummaryData.linkTo}>
          {boardSummaryData.boardTitle}
        </Link>
      </h3>
      <hr className="boardSummarySection--topHr" />
      <ul>
        {boardSummaryData.contents.slice(0, 4).map(list => (
          <li 
          key={`${list.boardTitle}${list.id}`}
          >
            <Link onClick={() => {
                setDefaultExpendedId(list.id);
                setDefaultExpendedCategory(list.category);

            }} to={boardSummaryData.linkTo}>
              {list.title}
            </Link>
          </li>
        ))}
      </ul>
      <hr className="boardSummarySection--bottomHr" />
    </section>
  )
}

export default BoardSummary;
