import "./academy.scss";
import React, {useCallback, useEffect, useState} from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import { FormControl, Input, InputAdornment, MenuItem, Select } from "@mui/material";
import VideoMsgBox from "../../components/videoMsgBox/VideoMsgBox";
import {useRecoilState, useRecoilValue, useRecoilValueLoadable} from "recoil";
import { isMiniNavOpenAtom, isVideoDialogOpenAtom } from "../../store/Atom";
import VideoDialog from "../../components/videoDialog/VideoDialog";
import VideoMsgDialog from "../../components/videoMsgDialog/VideoMsgDialog";
import {academyStorySelector, academyStoryTagSelector, pageContentSelector} from "../../store/Selector";
import Pagination from "../../components/pagination/Pagination";

function Academy() {
  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("academy"));
  const academyTags = useRecoilValueLoadable(academyStoryTagSelector);
  const [ selectedTag, setSelectedTag ] = useState("all");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [ searchedKeyword, setSearchedKeyword ] = useState("");
  //const [ filteredVideoData, setFilteredVideoData ] = useState([]);
  const [stories, setStories] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const academyDatas = useRecoilValueLoadable(academyStorySelector([selectedTag, selectedTitle, page]));
  const [tags, setTags] = useState([]);
  const isMiniNavOpen = useRecoilValue(isMiniNavOpenAtom);
  const [videoOpen, setVideoOpen] = useRecoilState(isVideoDialogOpenAtom);
  const body = document.body;

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  const requestFetchStory = useCallback(() => {
    if (academyDatas === null || academyDatas === undefined) {
      return;
    }
    switch (academyDatas.state) {
      case 'hasValue':
        const result = academyDatas.contents;
        const stories = result.contents;
        setStories(stories);
        setTotalPages(result.totalPages);
        //setFilteredVideoData(stories);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw academyDatas.contents;
    }
  }, [setStories, academyDatas]);

  const requestFetchTag = useCallback(() => {
    if (academyTags === null || academyTags === undefined) {
      return;
    }

    switch (academyTags.state) {
      case 'hasValue':
        const result = academyTags.contents;
        const tags = result.contents;

        //console.log("tags==>", tags);
        setTags(tags);
        break;
    }
  }, [setTags, academyTags]);

  const handleChange = (e) => {
    const targetTag = e.target.value;
    setSelectedTag(targetTag);

    if(targetTag === "all"){
      //setFilteredVideoData(stories);
    } else {
      // const filteredData = stories.filter(list => list.tags.includes(targetTag));
      // setFilteredVideoData(filteredData);
    }
  } 
  const handleSearch = (e) => {
    const keyword = e.target.value;
    setSearchedKeyword(keyword);
    setSelectedTitle(keyword);

    //let filteredData = [];
    if(selectedTag === "all"){
      //filteredData = academyDatas.filter(list => list.title.includes(keyword));
    } else {
      // const filteredTags = academyDatas.filter(list => list.tags.includes(selectedTag));
      // filteredData = filteredTags.filter(list => list.title.includes(keyword));
    }

    //setFilteredVideoData(filteredData);
  }

  const videoOpenfn = (url) => {
    setVideoOpen({
      isOpen: true,
      url
    })
  }

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  useEffect(() => {
    requestFetchStory();
  }, [requestFetchStory, page, selectedTag, selectedTitle]);

  useEffect(() => {
    requestFetchTag();
  }, [requestFetchTag]);

  useEffect(() => {
    if(videoOpen.isOpen){
      body.style.overflow = "hidden"
    }

    return () => {
      body.style.overflowY = "scroll"
    }

  }, [body.style, videoOpen]);

  useEffect(() => {
      setSearchedKeyword("");
  }, [selectedTag])

  const handleSearchUser = () => {
    console.log(`현재 페이지:${page}`);
  }

  const setCurrentPageNumber = (page) => {
    setPage(page);
  }

  return(
    <main className="academySection">
      {contents.map((list, index) => (
        <PageThumbnail
            key={index}
            imgUrl={`/download/${list.academy_header_image}`}
            description={list.academy_title}
            miniNavProps={
              {
                parentMenu: {
                  title: "STORY",
                },
                childMenu: {
                  title: "아카데미",
                  menuId: 2,
                }
              }
          }
        />
      ))}

      <section className="academySection__contentsWrap container">
        <div className="academySection__contentsWrap__titleWrap">
          <div className="academySection__contentsWrap__titleWrap__title">
            <h1>아카데미</h1>
            <h3>우리들의 이야기</h3>
          </div>
          <div className="academySection__contentsWrap__titleWrap--searchWrap">
            <FormControl variant="standard" sx={{mr: 3, minWidth: window.innerWidth <= 600 ? 0 : 120 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedTag}
                onChange={handleChange}
                defaultValue={"all"}
                className="academySection__selectInput"
                sx={{zIndex: isMiniNavOpen ? -1 : 1}}
              >
                <MenuItem value="all" >
                  <em>전체</em>
                </MenuItem>
                {tags.map((tag) => (
                  <MenuItem value={tag}>{tag}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Input
              variant="standard" 
              placeholder="제목을 검색해 보세요."
              endAdornment={
              <InputAdornment position="end">
                <img style={{width: 15}} src={require("../../assets/icons/ico-glass.png")} alt=""/>
              </InputAdornment>}
              onChange={(e) => handleSearch(e)}
              value={searchedKeyword}
              className="academySection__searchInput"
              sx={{zIndex: isMiniNavOpen ? -1 : 1}}
              />
          </div>
        </div>

        <div className="academySection__contentsWrap__showMoreBtn">
          <span onClick={() => window.open("https://www.youtube.com/@ds_3841/videos")}>다양한 컨텐츠 확인하기+</span>
        </div>
        
        <div className="academySection__contentsWrap__videoGrid">
          {stories.map(list => (
            <React.Fragment key={list.id}>
              <VideoMsgBox data={list} videoOpenfn={videoOpenfn}/>
            </React.Fragment>
          ))}
          {stories.length === 0 &&
            <>
              <div />
              <h1 className="academySection__contentsWrap__noSearch">조회된 영상이 없습니다.</h1>
              <div />
            </>
          }
        </div>

        <Pagination
            totalPages={totalPages}
            handleSearchUser={handleSearchUser}
            setCurrentPageNumber={setCurrentPageNumber}
        />
      </section>

      {/* common video modal */}
      <VideoDialog />
    </main >
  )
}

export default Academy;