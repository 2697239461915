import {selector, selectorFamily} from "recoil";
import {v1} from "uuid";

// 각 페이지의 컨텐츠 셀렉터
export const pageContentSelector = selectorFamily({
    key: `pageContent${v1()}`,
    get: (pageIdentity) => async () => {
        const content_response = await fetch(`/api/introduction/${pageIdentity}`);
        return await content_response.json();
    }
});

// 코드 셀렉터
export const codeSelector = selectorFamily({
    key: `code${v1()}`,
    get: (category) => async () => {
        const code_response = await fetch(`/api/code/${category}`);
        return await code_response.json();
    }
});


// 코드 통합 셀렉터(강좌신청용)
export const codeSummarySelector = selector({
    key: `codeSummary/${v1()}`,
    get: async () => {
        //SDS0005~0013까지 가져옴
        let codeInfos = [];
        for (let i=5; i<=13; i++) {
            let result = i.toString().padStart(2, "0");
            const code_response = await fetch(`/api/code/SDS00${result}`);
            const codeInfo = await code_response.json();
            codeInfos.push(codeInfo);
        }

        return codeInfos;
    }

});

// 공지사항, FAQ 셀렉터
export const boardSummarySelector = selector({
    key: `boardTitle/${v1()}`,
    get: async () => {
        const notice_response = await fetch('/api/notice/main');
        const noticeInfo = await notice_response.json();
        const faq_response = await fetch('/api/faq/main');
        const faqInfo = await faq_response.json();
        return [noticeInfo, faqInfo];
    }
});

// 공지사항, FAQ 셀렉터(페이징 처리)
export const boardNoticeSelector = selectorFamily({
    key: `boardNotice/${v1()}`,
    get: ([title, category, page]) => async () => {
        const notice_response = await fetch(`/api/notice/main?title=${title}&category=${category}&page=${page-1}`);
        return await notice_response.json();
    }
});

export const boardFaqSelector = selectorFamily({
    key: `boardFaq/${v1()}`,
    get: ([category, page]) => async () => {
        //console.log(category, page);
        const faq_response = await fetch(`/api/faq/main?category=${category}&page=${page-1}`);
        return await faq_response.json();
    }
});

// 아카데미 스토리 셀렉터
export const academyStorySelector = selectorFamily({
    key: `academyStory/${v1()}`,
    get: ([tag, title, page]) => async () => {
        //console.log(tag, title, page);
        let query;
        if (tag === "all") {
            query = `title=${title}&page=${page-1}`;
        } else {
            query = `tagsAdd=${tag}&title=${title}&page=${page-1}`;
        }
        const story_response = await fetch(`/api/story/main/academy?${query}`);
        return await story_response.json();
    }
});

// 아카데미 스토리 tag 셀렉터
export const academyStoryTagSelector = selector({
    key: `academyStoryTag/${v1()}`,
    get: async () => {
        const story_response = await fetch('/api/story/tag/academy');
        return await story_response.json();
    }
});

// 신한DS 스토리 셀렉터
export const dsStorySelector = selectorFamily({
    key: `dsStory/${v1()}`,
    get: ([tag, title, page]) => async () => {
        let query;
        if (tag === "all") {
            query = `title=${title}&page=${page-1}`;
        } else {
            query = `tagsAdd=${tag}&title=${title}&page=${page-1}`;
        }
        const story_response = await fetch(`/api/story/main/ds?${query}`);
        return await story_response.json();
    }
});

// ds 스토리 tag 셀렉터
export const dsStoryTagSelector = selector({
    key: `dsStoryTag/${v1()}`,
    get: async () => {
        const story_response = await fetch('/api/story/tag/ds');
        return await story_response.json();
    }
});

// 최근 스토리 셀렉터
export const recentStorySelector = selector({
    key: `recentStory/${v1()}`,
    get: async () => {
        const story_response = await fetch('/api/story/recent3');
        return await story_response.json();
    }
});

// blog 셀렉터
export const blogSelector = selectorFamily({
    key: `blog/${v1()}`,
    get: (page) => async () => {
        const blog_response = await fetch(`/api/blog/main?page=${page-1}`);
        return await blog_response.json();
    }
});

// blog detail 셀렉터
export const blogDetailSelector = selectorFamily({
    key: `blogDetail/${v1()}`,
    get: (blogId) => async () => {
        const blog_response = await fetch(`/api/blog/${blogId}`);
        return await blog_response.json();
    }
});

// 강좌 셀렉터
export const lectureSelector = selectorFamily({
    key: `lecture/${v1()}`,
    get: ([lectureType, lectureCategory]) => async () => {
        let url;
        //console.log('lectureType:', lectureType);
        lectureType = lectureType === undefined ? '01' : lectureType;
        if (lectureCategory === "") {
            url = `/api/lecture/main/${lectureType}`;
        } else {
            url = `/api/lecture/main/${lectureType}/${lectureCategory}`;
        }
        const lecture_response = await fetch(url);
        return await lecture_response.json();
    }
});

// 현재 진행중인 강좌 셀렉터
export const recentLectureSelector = selector({
    key: `recentLecture/${v1()}`,
    get: async () => {
        const lecture_response = await fetch('/api/lecture/current');
        return await lecture_response.json();
    }
});

// 강좌 상세 셀렉터
export const lectureDetailSelector = selectorFamily({
    key: `lectureDetail/${v1()}`,
    get: (lectureId) => async () => {
        const lecture_response = await fetch(`/api/lecture/${lectureId}`);
        return await lecture_response.json();
    }
});

// 강좌 지원자 상세 셀렉터
export const lectureMemberDetailSelector = selectorFamily({
    key: `lectureMemberDetail/${v1()}`,
    get: (memberId) => async () => {
        const member_response = await fetch(`/api/registration/${memberId}`);
        return await member_response.json();
    }
});

// 자료실 셀렉터
export const archiveSelector = selectorFamily({
    key: `archive/${v1()}`,
    get: (page) => async () => {
        const archive_response = await fetch(`/api/archive/main?page=${page-1}`);
        return await archive_response.json();
    }
});

// 자료실 detail 셀렉터
export const archiveDetailSelector = selectorFamily({
    key: `archiveDetail/${v1()}`,
    get: (archiveId) => async () => {
        const archive_response = await fetch(`/api/archive/${archiveId}`);
        return await archive_response.json();
    }
});

// 훈련시설 셀렉터
export const facilitySelector = selector({
    key: `facility/${v1()}`,
    get: async () => {
        const facility_response = await fetch('/api/facility/main');
        return await facility_response.json();
    }
});

// 페이지 메뉴 셀렉터
export const pageMenuSelector = selector({
    key: `pageMenu/${v1()}`,
    get: async () => {
        const page_response = await fetch('/api/page/main');
        return await page_response.json();
    }
});