import { ExpandMore } from "@mui/icons-material";
import { Button, MenuItem } from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import {useRecoilValueLoadable, useSetRecoilState} from "recoil";
import { isMiniNavOpenAtom } from "../../store/Atom";
import { pageMenuSelector, codeSelector } from "../../store/Selector";
import "./miniNav.scss";


function MiniNavTwoDeps({ childMenu }) {
  const [menus, setMenus] = useState([]);
  const menuData = useRecoilValueLoadable(pageMenuSelector);

  const [codes, setCodes] = useState([]);
  const codeDatas = useRecoilValueLoadable(codeSelector("SDS0001"));

  const [showMenu, setShowMenu] = useState(false);
  const isMiniNavOpen = useSetRecoilState(isMiniNavOpenAtom);
  const buttonRef = useRef(null);
  const subMenuRef = useRef(null);

  const requestFetchMenus = useCallback(() => {
    if (menuData === null || menuData === undefined) {
      return;
    }
    switch (menuData.state) {
      case 'hasValue':
        const result = menuData.contents;
        const contents = result.contents;
        setMenus(contents[0].subPages[childMenu.menuId].subPages);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw menuData.contents;
    }
  }, [setMenus, menuData, childMenu.menuId]);

  const requestFetchCodes = useCallback(() => {
    if (codeDatas === null || codeDatas === undefined) {
      return;
    }
    switch (codeDatas.state) {
      case 'hasValue':
        const result = codeDatas.contents;
        const contents = result.contents;
        setCodes(contents[0]);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw codeDatas.contents;
    }
  }, [setCodes, codeDatas]);

  useEffect(() => {
    requestFetchMenus();
  }, [requestFetchMenus]);

  useEffect(() => {
    requestFetchCodes();
  }, [requestFetchCodes]);
  
  const handleClick = () => {
    setShowMenu(prev => !prev);
    isMiniNavOpen(prev => !prev);
  };
  const handleClose = () => {
    setShowMenu(false);
    isMiniNavOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        subMenuRef.current && 
        buttonRef.current && 
        !subMenuRef.current.contains(event.target) && 
        !buttonRef.current.contains(event.target)
        ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(showMenu) {
      isMiniNavOpen(true);
    } else {
      isMiniNavOpen(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  return(
    <section className="miniNav-section">
      <div className="miniNav-section--line">|</div>
      <div className="miniNav-section--menuWrap">
        <Button
          id="basic-button"
          aria-haspopup="true"
          onClick={handleClick}
          className="miniNav-section--menuWrap__btn"
          ref={buttonRef}
        >
          <span>{childMenu.title}</span>
          <ExpandMore className={showMenu ? "arrow-up" : "arrow-down"} />
        </Button>
        <div className={`miniNav-section--menuWrap__menuBox ${showMenu ? "subMenuOpen" : null}`}  ref={subMenuRef}>
          <div className="miniNav-section--menuWrap__menuBox--contents">
              {childMenu.menuId !== 1 && menus.map((menu, idx) => {
                if (!menu.isHidden)
                  return <Link key={menu.url + idx} to={`/${menu.url}`}>
                          <MenuItem sx={{fontFamily: "나눔바른고딕"}} onClick={handleClose}>{menu.title}</MenuItem>
                        </Link>
                return <></>
              })}
              {childMenu.menuId === 1 && codes.map((list, idx) => {
                return <Link key={idx} to={`/trainingCourse/${list.codeValue}`}>
                  <MenuItem sx={{fontFamily: "나눔바른고딕"}} onClick={handleClose}>{list.codeName}</MenuItem>
                </Link>
              })}
          </div>
        </div>
      </div>
    </section>
  )
}


export default MiniNavTwoDeps;