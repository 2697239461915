import "../../pages/TrainingCourse/trainingDetail.scss";

function OverviewItem({img, title, contents}) {
    return (
        <div className="detailSection--contentsWrap__infoWrap--eachSection--overviewWrap--itemBox">
            <img src={img} alt=""/>
            <h1>{title}</h1>
            {contents}
        </div>
    )
}

export default OverviewItem;