import { ExpandMore } from "@mui/icons-material";
import { Button, MenuItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { isMiniNavOpenAtom } from "../../store/Atom";
import "./miniNav.scss";


function MiniNavOneDeps({ parentMenu }) {
  const [showMenu, setShowMenu] = useState(false);
  const isMiniNavOpen = useSetRecoilState(isMiniNavOpenAtom);
  const buttonRef = useRef(null);
  const subMenuRef = useRef(null);

  const handleClick = () => {
    setShowMenu(prev => !prev);
  };
  const handleClose = () => {
    setShowMenu(false);
    isMiniNavOpen(false);
  };

  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        subMenuRef.current && 
        buttonRef.current && 
        !subMenuRef.current.contains(event.target) && 
        !buttonRef.current.contains(event.target)
        ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(showMenu) {
      isMiniNavOpen(true);
    } else {
      isMiniNavOpen(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);


  return(
    <section className="miniNav-section depsOnePadding">
      <Link to={"/"}>
        <img className="miniNav-section--homeIco" src={require("../../assets/icons/ico-home-white.png")} alt="" />
      </Link>
      <div className="miniNav-section--line">|</div>
      <div className="miniNav-section--menuWrap">
        <Button
          id="basic-button"
          aria-haspopup="true"
          onClick={handleClick}
          className="miniNav-section--menuWrap__btn"
          ref={buttonRef}
        >
          <span>{parentMenu.title}</span>
          <ExpandMore className={showMenu ? "arrow-up" : "arrow-down"} />
        </Button>
        <div className={`miniNav-section--menuWrap__menuBox ${showMenu ? "subMenuOpen" : null}`} ref={subMenuRef}>
          <div className="miniNav-section--menuWrap__menuBox--contents">
            <Link to={"/introduction"}>
              <MenuItem sx={{fontFamily: "나눔바른고딕"}} onClick={handleClose}>사업 소개</MenuItem>
            </Link>
            <Link to={"/trainingCourse/01"}>
              <MenuItem sx={{fontFamily: "나눔바른고딕"}} onClick={handleClose}>훈련 과정</MenuItem>
            </Link>
            <Link to={"/videoMsg"}>
              <MenuItem sx={{fontFamily: "나눔바른고딕"}} onClick={handleClose}>STORY</MenuItem>
            </Link>
            <Link to={"/notice"}>
              <MenuItem sx={{fontFamily: "나눔바른고딕"}} onClick={handleClose}>훈련센터</MenuItem>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}


export default MiniNavOneDeps;