import "./notice.scss";
import {Accordion, AccordionDetails, AccordionSummary, Button, Input, InputAdornment, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {defaultExpendedIdAtom, defaultExpendedCategoryAtom, isMiniNavOpenAtom} from "../../store/Atom";
import {boardNoticeSelector,codeSelector, pageContentSelector} from "../../store/Selector";
import React, { useEffect, useLayoutEffect, useState, useCallback } from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import Pagination from "../../components/pagination/Pagination";

import newsNone from "../../assets/images/noticeImg/news_none.png";
import newsHover from "../../assets/images/noticeImg/news_hover.png";
import postingNone from "../../assets/images/noticeImg/posting_none.png";
import postingHover from "../../assets/images/noticeImg/posting_hover.png";
import {makeStyles} from "@mui/styles";
import { Parser } from "html-to-react";


const useStyles = makeStyles({
  firstStyle:
      {
        backgroundImage: `url(${newsNone})`,
        backgroundSize: "cover",
        '&:hover': {
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
        }
      },
  secondStyle:
      {
        backgroundImage: `url(${postingNone})`,
        backgroundSize: "cover",
        '&:hover': {
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
        }
      },

  firstStyle2:
      {
        backgroundImage: `url(${newsHover})`,
        backgroundSize: "cover",
        '&:hover': {
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
        }

      },
  secondStyle2:
      {
        backgroundImage: `url(${postingHover})`,
        backgroundSize: "cover",
        '&:hover': {
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
        }
      },

});


function Notice() {
  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("notice"));
  const [codes, setCodes] = useState([]);
  const codeDatas = useRecoilValueLoadable(codeSelector("SDS0014"));
  //const [ filteredData, setFilteredData ] = useState(boardSummary.contents[0].contents);
  //const [ filteredData, setFilteredData ] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedCate, setSelectedCate] = useState("");
  const [ notices, setNotices] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const boardSummary = useRecoilValueLoadable(boardNoticeSelector([selectedTitle, selectedCate, page]));
  const [ defaultExpendedId, setDefaultExpendedId ] = useRecoilState(defaultExpendedIdAtom);
  const [ defaultExpendedCategory, setDefaultExpendedCategory ] = useRecoilState(defaultExpendedCategoryAtom);
  const isMiniNavOpen = useRecoilValue(isMiniNavOpenAtom);

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  const requestFetchCodes = useCallback(() => {
    if (codeDatas === null || codeDatas === undefined) {
      return;
    }
    switch (codeDatas.state) {
      case 'hasValue':
        const result = codeDatas.contents;
        const contents = result.contents;
        // console.log(contents[0]);
        // console.log(contents[0][0].codeName);
        setCodes(contents[0]);
        if (defaultExpendedId === "") {
          setSelectedCate(contents[0][0].codeValue);
        }
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw codeDatas.contents;
    }
  }, [setCodes, codeDatas]);

  const requestFetchNotice = useCallback(() => {
    if (boardSummary === null || boardSummary === undefined) {
      return;
    }
    switch (boardSummary.state) {
      case 'hasValue':
        //console.log("hasValue");
        const result = boardSummary.contents;
        const notices = result.contents;
        setNotices(notices);
        setTotalPages(result.totalPages);
        //setFilteredData(notices);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw boardSummary.contents;
    }
  }, [setNotices, boardSummary]);

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  useEffect(() => {
    requestFetchCodes();
  }, [requestFetchCodes]);

  useEffect(() => {
    //console.log("useEffect");
    requestFetchNotice();
  }, [requestFetchNotice, page, selectedTitle]);

  const handleSearch = (e) => {
    //setSelectedTitle(e.target.value);
    const keyword = e.target.value;

    if(keyword.trim() === ""){
      setSelectedTitle("");

    } else {
      setSelectedTitle(keyword);
      setPage(1);
    }

  }

  const handleFilterCategory = async (newCategory) => {


    setDefaultExpendedId("");
    setSelectedCate(newCategory);


  }

  useEffect(() => {

    return(() => {
      setDefaultExpendedId("");
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useLayoutEffect(() => {
    if(defaultExpendedId !== ""){
      setDefaultExpendedId(defaultExpendedId);
    }

    if (defaultExpendedCategory !== "") {
      //setDefaultExpendedCategory(defaultExpendedCategory);
      setSelectedCate(defaultExpendedCategory);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchUser = () => {
    console.log(`현재 페이지:${page}`);
    //setSelectedTitle(keyword);
  }

  const setCurrentPageNumber = (page) => {
    setPage(page);
  }

  const createMarkup = htmlString => ({ __html: htmlString });

  const classes = useStyles();
  const buttonStep = [classes.firstStyle, classes.secondStyle];
  const buttonStepSelected = [classes.firstStyle2, classes.secondStyle2];

  return(
      <main className="noticeSection">
        {contents.map(list => (
          <PageThumbnail
              imgUrl={`/download/${list.notice_header_image}`}
              description={list.notice_title}
              miniNavProps={
                {
                  parentMenu: {
                    title: "훈련 센터",
                  },
                  childMenu: {
                    title: "공지사항",
                    menuId: 3,
                  }
                }
              }
          />
        ))}

        <section className="noticeSection__boardWrap container">
          <div className="noticeSection__boardWrap__titleWrap">
            <h1>공지사항</h1>
            <div>
              <div style={{textAlign: "right", marginBottom: "30px"}}>
                <Input
                    variant="standard"
                    placeholder="제목을 검색해 보세요."
                    endAdornment={
                      <InputAdornment position="end">
                        <img style={{width: 15}} src={require("../../assets/icons/ico-glass.png")} alt=""/>
                      </InputAdornment>}
                    onChange={(e) => handleSearch(e)}
                    className="noticeSection__searchInput"
                    sx={{zIndex: isMiniNavOpen ? -1 : 1}}
                />
              </div>
              <div className="noticeSection__boardWrap__titleWrap--btnWrap">
                {codes.map((list, idx) => (
                    <Button
                        key={idx}
                        variant="contained"
                        className={selectedCate === list.codeValue ? buttonStepSelected[idx] : buttonStep[idx]}
                        onClick={() => handleFilterCategory(list.codeValue)}
                        sx={{zIndex: isMiniNavOpen ? -1 : 1}}
                    >
                      {/*{list.codeName}*/}
                    </Button>
                ))}


              </div>
            </div>

          </div>
          <div className="noticeSection__boardWrap">
            <div className="noticeSection__boardWrap--top">
              <span>카테고리</span>
            </div>
            {notices.map(list => (
                <React.Fragment key={list.id}>
                  <Accordion
                      className="noticeSection__boardWrap--accordion"
                      onClick={() => {
                        if(defaultExpendedId === list.id){
                          setDefaultExpendedId("");
                        } else {
                          setDefaultExpendedId(list.id)
                        }
                      }}
                      expanded={list.id === defaultExpendedId ? true : false}
                  >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion__summary"
                    >
                      <Typography>
                        <span className="accordion__category">[{list.categoryName}]</span>
                        <span className="accordion__title">{list.title}</span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion__detailBox">
                        <div>{Parser().parse(list.paragraph)}</div>
                        {list.image === "" ? null :
                            <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                              <img style={{width: "100%"}} src={list.image} alt=""/>
                            </div>
                        }
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </React.Fragment>
            ))}
          </div>

          <Pagination
              totalPages={totalPages}
              handleSearchUser={handleSearchUser}
              setCurrentPageNumber={setCurrentPageNumber}
          />
        </section>
      </main >
  )

}

export default Notice;