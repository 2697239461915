import "./archive.scss";

import React, {useCallback, useEffect, useState} from "react";
import PageThumbnail from "../../components/pageThumbnail/PageThumbnail";
import {useRecoilValueLoadable} from "recoil";
import {archiveSelector, pageContentSelector} from "../../store/Selector";

import Pagination from "../../components/pagination/Pagination";
import { Parser } from "html-to-react";
import ArchiveMsgBox from "../../components/archiveMsgBox/ArchiveMsgBox";

function Archive() {
    const [contents, setContents] = useState([]);
    const contentsDatas = useRecoilValueLoadable(pageContentSelector("archive"));
    const [archives, setArchives] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const archiveDatas = useRecoilValueLoadable(archiveSelector(page));

    const requestFetchContents = useCallback(() => {
        if (contentsDatas === null || contentsDatas === undefined) {
            return;
        }
        switch (contentsDatas.state) {
            case 'hasValue':
                const result = contentsDatas.contents;
                const contents = result.contents;
                setContents(contents);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw contentsDatas.contents;
        }
    }, [setContents, contentsDatas]);

    const requestFetchArchive = useCallback(() => {
        if (archiveDatas === null || archiveDatas === undefined) {
            return;
        }
        switch (archiveDatas.state) {
            case 'hasValue':
                const result = archiveDatas.contents;
                const archives = result.contents;
                console.log(archives);
                setArchives(archives);
                setTotalPages(result.totalPages);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw archiveDatas.contents;
        }
    }, [setArchives, archiveDatas]);

    useEffect(() => {
        requestFetchContents();
    }, [requestFetchContents]);

    useEffect(() => {
        requestFetchArchive();
    }, [requestFetchArchive, page]);

    const handleSearchUser = () => {
        console.log(`현재 페이지:${page}`);
    }

    const setCurrentPageNumber = (page) => {
        setPage(page);
    }

    return (
        <main className="archiveSection">
            {contents.map((list, index) => (
                <PageThumbnail
                    key={index}
                    imgUrl={`/download/${list.archive_header_image}`}
                    description={list.archive_title}

                    miniNavProps={
                        {
                            parentMenu: {
                                title: "훈련 센터",
                            },
                            childMenu: {
                                title: "자료실",
                                menuId: 3,
                            }
                        }
                    }
                />
            ))}

            <section className="archiveSection__contentsWrap container">
                <div className="archiveSection__contentsWrap__titleWrap">
                    <div className="archiveSection__contentsWrap__titleWrap__title">
                        <h1>자료실</h1>
                        <h3>신한DS 아카데미 자료실</h3>
                    </div>

                </div>



                <div className="archiveSection__contentsWrap__videoGrid">
                    {archives.map(list => (
                        <React.Fragment key={list.id}>
                            <ArchiveMsgBox data={list} />
                        </React.Fragment>
                    ))}
                    {archives.length === 0 &&
                        <>
                            <div />
                            <h1 className="archiveSection__contentsWrap__noSearch">조회된 자료가 없습니다.</h1>
                            <div />
                        </>
                    }

                </div>
                {/*<p>총 페이지수: {totalPages}</p><p>현재 페이지: {page}</p>*/}
                <Pagination
                    totalPages={totalPages}
                    handleSearchUser={handleSearchUser}
                    setCurrentPageNumber={setCurrentPageNumber}
                />
            </section>


        </main >
    )
}

export default Archive;