import "./registration.scss";
import Post from "../../components/postCode/Post";

import React, {useCallback, useEffect, useState} from "react";
import {lectureDetailSelector, pageContentSelector, codeSummarySelector, lectureMemberDetailSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";
import {useParams, useNavigate} from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {useForm} from "react-hook-form";
import DetailThumbnail from "../../components/detailThumbnail/DetailThumbnail";




function RegistrationEdit() {
    const {
        register,
        handleSubmit ,
        setValue,
        formState: { isSubmitting, isSubmitted, errors}
    } = useForm();


    let params = useParams();
    const navigate = useNavigate();
    const [contents, setContents] = useState([]);
    const contentsDatas = useRecoilValueLoadable(pageContentSelector("trainingDetail"));
    const codeSummaryDatas = useRecoilValueLoadable(codeSummarySelector);

    const lectureData = useRecoilValueLoadable(lectureDetailSelector(params?.lectureId));
    const lectureMemberData = useRecoilValueLoadable(lectureMemberDetailSelector(params?.memberId));
    const [lecture, setLecture] = useState([]);
    const [member, setMember] = useState([]);
    const [emailList, setEmailList] = useState([]);
    const [finalEducationList, setFinalEducationList] = useState([]);
    const [motivationList, setMotivationList] = useState([]);
    const [jobHistoryList, setJobHistoryList] = useState([]);
    const [unemploymentPeriodList, setUnemploymentPeriodList] = useState([]);
    const [prioritySelectionList, setPrioritySelectionList] = useState([]);
    const [militaryList, setMilitaryList] = useState([]);
    const [accuisitionPathList, setAccuisitionPathList] = useState([]);

    // 나이
    const [date, setDate] = useState('');
    const [age, setAge] = useState(0);

    //우편번호 팝업
    const [popup, setPopup] = useState(false);

    // 학교정보 보이기
    const [showSchoolInfo, setShowSchoolInfo] = useState(false);

    const handleShowSchoolInfo = selectedOption => {
        console.log(selectedOption);
        setShowSchoolInfo(selectedOption.target.value === "01" ||
            selectedOption.target.value === "02" || selectedOption.target.value === "03");
    }

    const handleComplete = (data) => {
        setPopup(!popup);
    }

    const closePostCode = (zipcode, address) => {
        // console.log("closePostCode");
        // console.log("address", enroll_company.address);
        setValue("upyeon_no", zipcode);
        setValue("upyeon_juso", address);
    }

    const requestFetchContents = useCallback(() => {
        if (contentsDatas === null || contentsDatas === undefined) {
            return;
        }
        switch (contentsDatas.state) {
            case 'hasValue':
                const result = contentsDatas.contents;
                const contents = result.contents;
                setContents(contents);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw contentsDatas.contents;
        }
    }, [setContents, contentsDatas]);

    const requestFetchLecture = useCallback(() => {
        if (lectureData === null || lectureData === undefined) {
            return;
        }
        switch (lectureData.state) {
            case 'hasValue':
                const result = lectureData.contents;
                const lectures = result.contents;
                //console.log(lectures);
                setLecture(lectures[0]);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw lectureData.contents;
        }
    }, [setLecture, lectureData]);

    const requestFetchLectureMember = useCallback(() => {
        if (lectureMemberData === null || lectureMemberData === undefined) {
            return;
        }
        switch (lectureMemberData.state) {
            case 'hasValue':
                const result = lectureMemberData.contents;
                const members = result.contents;
                //console.log(members);
                const session_uid = sessionStorage.getItem("uid");
                //console.log("session uid=" + session_uid);
                //console.log("member uid=" + member.uid);
                if (session_uid === null) {
                    alert("접수내역을 확인해 주십시오");
                    navigate('/registrationCheck/' + params?.lectureId);
                } else {

                    if (members.length === 0 || session_uid !== members[0].uid) {
                        alert("본인의 접수내역만 확인가능합니다.");
                        navigate('/registrationCheck/' + params?.lectureId);
                    } else {
                        setMember(members[0]);
                    }
                }
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw lectureMemberData.contents;
        }
    }, [setMember, lectureMemberData, params?.lectureId, navigate]);


    const requestFetchSummaries = useCallback(() => {
        //console.log("1", codeSummaryDatas);
        if (codeSummaryDatas === null || codeSummaryDatas === undefined) {
            return;
        }
        switch (codeSummaryDatas.state) {
            case 'hasValue':
                const result = codeSummaryDatas.contents;
                //console.log("2", result);
                //const summaries = result.contents;
                //setRecentSummaries(result);
                // codeValue, codeName => value, label
                setEmailList(result[0].contents[0]);  // E-mail은 특별한 문제 없음
                //최종학력
                // let arr = JSON.parse(JSON.stringify(result[1].contents[0]));
                // arr.forEach(obj => renameKey(obj, 'codeValue', 'value'));
                // arr.forEach(obj => renameKey(obj, 'codeName', 'label'));
                setFinalEducationList(result[1].contents[0]);

                //지원동기
                // let arr = JSON.parse(JSON.stringify(result[2].contents[0]));
                // arr.forEach(obj => renameKey(obj, 'codeValue', 'value'));
                // arr.forEach(obj => renameKey(obj, 'codeName', 'label'));
                setMotivationList(result[2].contents[0]);

                // 취업이력
                // arr = JSON.parse(JSON.stringify(result[3].contents[0]));
                // arr.forEach(obj => renameKey(obj, 'codeValue', 'value'));
                // arr.forEach(obj => renameKey(obj, 'codeName', 'label'));
                setJobHistoryList(result[3].contents[0]);

                // 실직기간
                // arr = JSON.parse(JSON.stringify(result[4].contents[0]));
                // arr.forEach(obj => renameKey(obj, 'codeValue', 'value'));
                // arr.forEach(obj => renameKey(obj, 'codeName', 'label'));

                setUnemploymentPeriodList(result[4].contents[0]);

                // 병역관계
                // arr = JSON.parse(JSON.stringify(result[5].contents[0]));
                // arr.forEach(obj => renameKey(obj, 'codeValue', 'value'));
                // arr.forEach(obj => renameKey(obj, 'codeName', 'label'));

                setMilitaryList(result[5].contents[0]);

                // 최초 정보습득경로
                // arr = JSON.parse(JSON.stringify(result[6].contents[0]));
                // arr.forEach(obj => renameKey(obj, 'codeValue', 'value'));
                // arr.forEach(obj => renameKey(obj, 'codeName', 'label'));

                setAccuisitionPathList(result[6].contents[0]);

                // 우선선발
                setPrioritySelectionList(result[8].contents[0]);
                break;

            case 'loading':
                return <div>로딩중입니다...</div>;
            case 'hasError':
                throw codeSummaryDatas.contents;
        }
    }, [codeSummaryDatas]);



    useEffect(() => {
        requestFetchContents();
    }, [requestFetchContents]);

    useEffect(() => {
        requestFetchLecture();
    }, [requestFetchLecture, params?.lectureId]);

    useEffect(() => {
        requestFetchLectureMember();
    }, [requestFetchLectureMember, params?.lectureId, params?.memberId]);

    useEffect(() => {
        requestFetchSummaries();
    }, [requestFetchSummaries]);

    useEffect(() => {
        if (lecture.length !== 0 && member.length !== 0) {
            //reset({});
            console.log("1:", lecture, member);
            setValue("lectureId", lecture.id);

            // 세션을 체크해서 uid가 있고 등록확인 페이지에서 얻은 uid와 같을 경우에만 편집화면을 보여줌
            // 지원자정보
            setValue("uname", member.uname);
            setValue("uid", member.uid);

            setValue("version", member.version);
            setValue("createdAt", member.createdAt);
            setValue("pwd", member.pwd);
            setValue("uploadFileId", member.uploadFileId);

            setValue("birthdate", moment(member.birthday).format('YYYY-MM-DD'));

            // 나이 설정
            const age = Math.floor(moment().diff(moment(member.birthday), 'years', true));
            setAge(age);

            setValue("gender", member.gender);
            setValue("foreigner", member.isForeigner ? "1" : "");
            const phone1 = (member.handphone || '').split('-');
            setValue("mobile1", phone1[0]);
            setValue("mobile2", phone1[1]);
            setValue("mobile3", phone1[2]);
            const phone2 = (member.handphone2 || '').split('-');
            //
            if (phone2 !== undefined && phone2.length === 3) {
                setValue("tel_no1", phone2[0]);
                setValue("tel_no2", phone2[1]);
                setValue("tel_no3", phone2[2]);
            }
            // //
            const email = (member.email || '').split('@');
            setValue("email", email[0]);
            setValue("email_url", email[1]);
            // //
            const address = (member.address || '').split('|');
            setValue("upyeon_no", address[0]);
            setValue("upyeon_juso", address[1]);
            setValue("sangse_juso", address[2]);
            if (lecture.lectureType === "01") {
                //console.log("finalEducation:", member.finalEducation);
                // 학력사항
                setValue("finalEducation", member.finalEducation);
                // 학교명
                setValue("schoolName", member.schoolName);
                // 전공
                setValue("major", member.major);
                // 졸업년월
                if (member.graduationDate !== null) {
                    setValue("graduationdate", moment(member.graduationDate).format('YYYY-MM-DD'));
                }

                if (member.schoolName?.length > 0) {
                    setShowSchoolInfo(true);
                }

                // 기타입력 사항
                setValue("donggi", member.donggi);
                setValue("jobHistory", member.jobHistory);
                setValue("unemployeementPeriod", member.unemployeementPeriod);
                setValue("military", member.military);
                setValue("accuisitionPath", member.accuisitionPath);
                //우선선발
                const priority = (member.prioritySelection || '').split('|');
                setValue("priority", priority);
                //관련사항
                setValue("bigo", member.bigo);

            } else {
                // 재직자
                setValue("companyname", member.companyname);
                setValue("jiktel", member.jiktel);
            }

            //reset({lecture, member});
        }


    });

    const submitForm = (data) => {
        //console.log("1:", data);
        const formData = new FormData();
        const handphone = data.mobile1 + "-" + data.mobile2 + "-" + data.mobile3;
        const handphone2 = data.tel_no1 + "-" + data.tel_no2 + "-" + data.tel_no3;
        const email = data.email + "@" + data.email_url;
        const address = data.upyeon_no + "|" + data.upyeon_juso + "|" + data.sangse_juso;
        const prioritySelection = (data.priority === undefined || !data.priority) ? "" : data.priority.join("|");
        const lectureId = params?.lectureId;
        const memberId = params?.memberId;
        const birthday = data.birthdate + "T00:00:00";
        const graduationDate = (typeof data.graduationdate !== 'undefined' && data.graduationdate !== "") ? data.graduationdate + "T00:00:00" : "";
        const isCom = lecture.lectureType !== '01';  // 재직자여부
        const role = "USER"; // 일반사용자
        const isForeigner = data.foreigner === '1';

        formData.append("memberPhoto", data.memberImage[0]);
        data = { ...data, handphone, handphone2, email, address, prioritySelection, birthday, graduationDate, isCom, role, isForeigner};
        //console.log("2:", data);
        const json = JSON.stringify(data);
        const blob = new Blob([json], { type: "application/json" });
        formData.append("inputMember", blob);

        return fetch(`/api/registration/update/${memberId}`, {
            method: "POST",
            body: formData,

        }).then((response) => {
            if (response.ok) {
                console.log("수정 성공");
                alert("훈련 신청 수정이 완료되었습니다.");
                navigate("/trainingDetail/" + lectureId);
            } else {
                console.error("Error 메시지를 확인하세요.")
            }
        })

    };

    const handleDateChange = (event) => {
        setDate(event.target.value);
        console.log(date);
        const now = moment();
        const birthdate = moment(date);
        const age = Math.floor(now.diff(birthdate, 'years', true));
        if (!isNaN(age)) {
            setAge(age);
        }
    };

    return(
        <main className="registrationSection">
            {contents.map((list, idx) => (
                <DetailThumbnail
                    key={lecture.id}
                    defaultImgUrl={`/download/${list.trainingDetail_header_image}`}
                    imgUrl={lecture.backgroundUrl}
                    lecture={lecture}
                    miniNavProps={
                        {
                            parentMenu: {
                                title: "훈련 과정",
                            },
                            childMenu: {
                                title: lecture.lectureType === '01' ? "취업 훈련" : (lecture.lectureType === '02' ? "직무 훈련" : "원격 훈련"),
                                menuId: 1,
                            }
                        }
                    }
                />
            ))}
            <section className="registrationSection__contentsWrap container">
                <div className="registrationSection__contentsWrap__titleWrap">
                    <div className="registrationSection__contentsWrap__titleWrap__title">
                        <h1>{lecture.title}</h1>
                        <h3>수강신청 확인/수정폼입니다</h3>
                    </div>

                </div>
                <div className="registrationSection__contentsWrap__registerForm">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="titleType10">
                            <span>지원자 정보</span>
                        </div>
                        <div className="viewType01 inputTb2 border photoUse marB_50">
                            <table>
                                <colgroup>
                                    <col id="title" />
                                    <col />
                                    <col id="title2"/>
                                    <col />
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>한글성명 <span className="rq">*</span></th>
                                    <td>
                                        <input type="hidden" id="lectureId" name="lectureId" {...register("lectureId")} />
                                        <input type="hidden" id="version" name="version" {...register("version")} />
                                        <input type="hidden" id="createdAt" name="createdAt" {...register("createdAt")} />
                                        <input type="hidden" id="pwd" name="pwd" {...register("pwd")} />
                                        <input type="hidden" id="uid" name="uid" {...register("uid")} />
                                        <input type="hidden" id="uploadFileId" name="uploadFileId" {...register("uploadFileId")} />
                                        <input className="size01 gray" type="text" name="ireum_kor" readOnly="" id="ireum_kor"
                                               aria-invalid={isSubmitted ? (errors.uname ? "true" : "false") : undefined}
                                               {...register("uname", {
                                                   required: "한글성명은 필수 입력입니다."
                                               })} />
                                        {errors.uname && <small role="alert">{errors.uname.message}</small> }
                                    </td>
                                    <th>생년월일,성별 <span className="rq">*</span></th>
                                    <td>

                                        <input className="size01 gray" type="date" name="birthdate" id="birthdate"
                                               aria-invalid={isSubmitted ? (errors.birthdate ? "true" : "false") : undefined}
                                               {...register("birthdate", {
                                                   required: "생년월일은 필수 입력입니다."
                                               })} onChange={handleDateChange}/>
                                        {errors.birthdate && <small role="alert">{errors.birthdate.message}</small> }
                                        &nbsp;
                                        {age}세
                                        &nbsp;
                                        <input type="radio" name="gender" id="t_man" className="radio" value="01" checked="checked" {...register("gender")} /> <label htmlFor="t_man">남</label>
                                        <input type="radio" name="gender" id="t_woman" className="radio" value="02" {...register("gender")} /> <label htmlFor="t_woman" className="kcci_mar_r50">여</label>
                                        &nbsp;&nbsp;
                                        <input type="checkbox" id="foreigner" name="foreigner" value="1" {...register("foreigner")} /> <label htmlFor="foreigner">외국인</label>
                                    </td>
                                </tr>

                                <tr>
                                    <th>핸드폰 <span className="rq">*</span></th>
                                    <td colSpan="3">
                                        <input className="sel01" type="text" name="mobile1" id="mobile1" maxLength="3"
                                               aria-invalid={isSubmitted ? (errors.mobile1 ? "true" : "false") : undefined}
                                               {...register("mobile1", {
                                                   required: "핸드폰 번호1은 필수 입력입니다."
                                               })}/>
                                        <span className="dash2">-</span>
                                        <input className="sel01" type="text" name="mobile2" id="mobile2" maxLength="4"
                                               aria-invalid={isSubmitted ? (errors.mobile2 ? "true" : "false") : undefined}
                                               {...register("mobile2", {
                                                   required: "핸드폰 번호2는 필수 입력입니다."
                                               })}/>
                                        <span className="dash2">-</span>
                                        <input className="sel01" type="text" name="mobile3" id="mobile3" maxLength="4"
                                               aria-invalid={isSubmitted ? (errors.mobile3 ? "true" : "false") : undefined}
                                               {...register("mobile3", {
                                                   required: "핸드폰 번호3은 필수 입력입니다."
                                               })}/>
                                        {errors.mobile1 && <><br /><small role="alert">{errors.mobile1.message}</small></> }
                                        {errors.mobile2 && <><br /><small role="alert">{errors.mobile2.message}</small></> }
                                        {errors.mobile3 && <><br /><small role="alert">{errors.mobile3.message}</small></> }

                                    </td>
                                </tr>

                                <tr>
                                    <th>추가연락처</th>
                                    <td colSpan="3"><input className="sel01" type="text" name="tel_no1" id="tel_no1" maxLength="3" {...register("tel_no1")} />
                                        <span className="dash2">-</span>
                                        <input className="sel01" type="text" name="tel_no2" id="tel_no2" maxLength="4" {...register("tel_no2")}/>
                                        <span className="dash2">-</span>
                                        <input className="sel01" type="text" name="tel_no3" id="tel_no3" maxLength="4" {...register("tel_no3")} />
                                    </td>
                                </tr>


                                <tr>
                                    <th>이메일 <span className="rq">*</span></th>
                                    <td colSpan="3">
                                        <input className="sel01" type="text" style={{width: "140px"}} maxLength="30" name="email" id="email"
                                               aria-invalid={isSubmitted ? (errors.email ? "true" : "false") : undefined}
                                               {...register("email", {
                                                   required: "이메일은 필수 입력입니다."
                                               })}/>
                                        <span className="dash2">@</span>
                                        <input name="email_url" className="size09" id="email_url" type="text"
                                               aria-invalid={isSubmitted ? (errors.email_url ? "true" : "false") : undefined}
                                               {...register("email_url", {
                                                   required: "이메일url은 필수 입력입니다."
                                               })}/>
                                        <div className="defaultSel ml5">
                                            <select name="mail_nm1" className="sel01" id="mail_nm1"
                                                    {...register("mail_nm1", {
                                                        onChange: (e) => setValue("email_url", e.target.value)
                                                    })}
                                                >
                                                <option value="">직접입력</option>
                                                {emailList.map((list, idx) => (
                                                    <option key={idx} value={list.codeName}>{list.codeName}</option>
                                                ))}
                                               </select>
                                        </div>
                                        {errors.email && <><br /><small role="alert">{errors.email.message}</small></> }
                                        {errors.email_url && <><br /><small role="alert">{errors.email_url.message}</small></> }
                                    </td>
                                </tr>
                                <tr>
                                    <th>현주소 <span className="rq">*</span></th>
                                    <td colSpan="3">
                                        <div className="line">
                                            <input className="size06" type="text" name="upyeon_no" id="upyeon_no"
                                                   aria-invalid={isSubmitted ? (errors.upyeon_no ? "true" : "false") : undefined}
                                                   {...register("upyeon_no", {
                                                       required: "우편번호는 필수 입력입니다."
                                                   })}/>
                                            <button className="btnAddrNum"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleComplete();
                                                }}>
                                                <span>우편번호검색<FontAwesomeIcon icon={faMagnifyingGlass}/></span>
                                            </button>
                                            {popup && (
                                                <Post onClose={closePostCode}/>
                                            )}
                                            {errors.upyeon_no && <small role="alert">{errors.upyeon_no.message}</small> }
                                        </div>
                                        <input className="size10" type="text" name="upyeon_juso" id="upyeon_juso"
                                               aria-invalid={isSubmitted ? (errors.upyeon_juso ? "true" : "false") : undefined}
                                               {...register("upyeon_juso", {
                                                   required: "우편주소는 필수 입력입니다."
                                               })}/>
                                        <input className="size09 ml5" type="text" name="sangse_juso" id="sangse_juso" maxLength="100"
                                               aria-invalid={isSubmitted ? (errors.sangse_juso ? "true" : "false") : undefined}
                                               {...register("sangse_juso", {
                                                   required: "상세주소는 필수 입력입니다."
                                               })}/>
                                        {errors.upyeon_juso && <><br /><small role="alert">{errors.upyeon_juso.message}</small></> }
                                        {errors.sangse_juso && <><br /><small role="alert">{errors.sangse_juso.message}</small></> }
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="photoPop">
                                <div className="in">

                                    <div className="img" id="picImg">
                                        <img alt="반명함판 사진" style={{width: "158px",  height: "211px"}}
                                             src={member.thumbnailImage} />
                                    </div>

                                    <div className="fileWrap">
                                        <div className="fileBox">
                                            <div className="fileInput">
                                                <input type="file" name="memberImage"
                                                       aria-invalid={isSubmitted ? (errors.memberImage ? "true" : "false") : undefined}
                                                       {...register("memberImage", {
                                                           //required: "반명함판 사진은 필수 입력입니다."
                                                       })}/>

                                                {/*<span>사진 업로드<FontAwesomeIcon icon={faCamera}/></span>*/}
                                                {errors.memberImage && (<><br/><small role="alert">{errors.memberImage.message}</small></>) }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(lecture.lectureType === '01' && <><div className="titleType10">
                            <span>학력 사항</span>
                        </div>
                        <div className="viewType01 inputTb2 border marB_50">
                            <table>
                                <colgroup>
                                    <col id="title" />
                                    <col />
                                </colgroup>

                                <tbody>
                                <tr>
                                    <th>최종학력 구분 <span className="rq">*</span></th>
                                    <td colSpan="3">
                                        <div className="defaultSel">


                                            <select className="changeTb size06" id="finalEducation" name="finalEducation"
                                                    aria-invalid={isSubmitted ? (errors.finalEducation ? "true" : "false") : undefined}
                                                    {...register("finalEducation", {
                                                        required: "최종학력 구분은 필수 입력입니다."
                                                    })}
                                                    onChange={handleShowSchoolInfo}
                                            >
                                                <option value="">선 택</option>
                                                {finalEducationList.map((list, idx) => (
                                                    <option key={idx} value={list.codeValue}>{list.codeName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {/*<span className="redInfo">※ 고등학교 졸업예정자는 고졸을 선택하여 주십시오.</span>*/}
                                        {errors.finalEducation && <small role="alert">{errors.finalEducation.message}</small> }

                                        {showSchoolInfo && <><input className="size09 ml5" type="text"  maxLength="30" name="schoolName" id="schoolName"
                                                                    placeholder="학교명"
                                                                    aria-invalid={isSubmitted ? (errors.schoolName ? "true" : "false") : undefined}
                                                                    {...register("schoolName", {
                                                                        required: "학교명은 필수 입력입니다."
                                                                    })}/>

                                            <input className="size09 ml5" type="text"  maxLength="30" name="major" id="major"
                                                   placeholder="전공"
                                                   aria-invalid={isSubmitted ? (errors.major ? "true" : "false") : undefined}
                                                   {...register("major", {
                                                       required: "전공은 필수 입력입니다."
                                                   })}/>
                                            &nbsp;
                                            <label for="graduationdate">졸업년월</label>&nbsp;
                                            <input className="size01 gray" type="date" name="graduationdate" id="graduationdate"
                                                   aria-invalid={isSubmitted ? (errors.graduationdate ? "true" : "false") : undefined}
                                                   {...register("graduationdate", {
                                                       required: "졸업년월은 필수 입력입니다."
                                                   })} />
                                            {errors.schoolName && <small role="alert">{errors.schoolName.message}</small> }
                                            {errors.major && <small role="alert">{errors.major.message}</small> }
                                            {errors.graduationdate && <small role="alert">{errors.graduationdate.message}</small>}
                                        </>}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="titleType10">
                            <span>기타입력 사항</span>
                        </div>
                        <div className="viewType01 inputTb2 border marB_50">
                            <table>
                                <colgroup>
                                    <col id="title" />
                                    <col />
                                    <col id="title2" />
                                    <col />
                                </colgroup>
                                <tbody>

                                    <tr>
                                        <th>지원동기 <span className="rq">*</span></th>
                                        <td colSpan="3">
                                            <div className="defaultSel">
                                                <select title="지원동기" className="size06" id="jiwon_donggi_cd" name="jiwon_donggi_cd"
                                                        aria-invalid={isSubmitted ? (errors.donggi ? "true" : "false") : undefined}
                                                        {...register("donggi", {
                                                            required: "지원동기는 필수 입력입니다."
                                                        })}
                                                >
                                                    <option value="">선 택</option>
                                                    {motivationList.map((list, idx) => (
                                                        <option key={idx} value={list.codeValue}>{list.codeName}</option>
                                                    ))}
                                                </select>

                                                {/*<Select
                                                    value={{label: member.donggiName, value: member.donggi}}
                                                    options={motivationList}
                                                />*/}

                                            </div>
                                            {errors.donggi && <small role="alert">{errors.donggi.message}</small> }
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>취업이력 <span className="rq">*</span></th>
                                        <td>
                                            <div className="defaultSel">
                                                <select title="취업이력" className="size06"  name="jeonjik_cd" id="jeonjik_cd"
                                                        aria-invalid={isSubmitted ? (errors.jobHistory ? "true" : "false") : undefined}
                                                        {...register("jobHistory", {
                                                            required: "취업이력은 필수 입력입니다."
                                                        })}
                                                >
                                                    <option value="">선 택</option>
                                                    {jobHistoryList.map((list, idx) => (
                                                        <option key={idx} value={list.codeValue}>{list.codeName}</option>
                                                    ))}
                                                </select>
                                                {/*<Select
                                                    value={{label: member.jobHistoryName, value: member.jobHistory}}
                                                    options={jobHistoryList}
                                                />*/}
                                            </div>
                                            {errors.jobHistory && <small role="alert">{errors.jobHistory.message}</small> }
                                        </td>
                                        <th>실직기간 <span className="rq">*</span></th>
                                        <td>
                                            <div className="defaultSel">
                                                <select title="실직기간" className="size06" name="siljik_gigan_cd" id="siljik_gigan_cd"
                                                        aria-invalid={isSubmitted ? (errors.unemployeementPeriod ? "true" : "false") : undefined}
                                                        {...register("unemployeementPeriod", {
                                                            required: "실직기간은 필수 입력입니다."
                                                        })}
                                                >
                                                    <option value="">선 택</option>
                                                    {unemploymentPeriodList.map((list, idx) => (
                                                        <option key={idx} value={list.codeValue}>{list.codeName}</option>
                                                    ))}
                                                </select>
                                                {/*<Select
                                                    value={{label: member.unemployeementPeriodName, value: member.unemployeementPeriod}}
                                                    options={unemploymentPeriodList}
                                                />*/}
                                            </div>
                                            {errors.unemployeementPeriod && <small role="alert">{errors.unemployeementPeriod.message}</small> }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>우선선발</th>
                                        <td>
                                            {prioritySelectionList.map((list, idx) => (
                                                <div className="baseCheck" key={idx}>
                                                    <input type="checkbox" id={list.id}  name="seonbal_cd" value={list.codeValue} {...register("priority")}/>
                                                    <label htmlFor={list.id}>{list.codeName}</label>
                                                </div>
                                            ))}
                                        </td>
                                        <th>병역관계 <span className="rq">*</span></th>
                                        <td>
                                            <div className="defaultSel">
                                                <select title="병역관계" className="size06" name="byeongyeok_cd" id="byeongyeok_cd"
                                                        aria-invalid={isSubmitted ? (errors.military ? "true" : "false") : undefined}
                                                        {...register("military", {
                                                            required: "병역관계는 필수 입력입니다."
                                                        })}
                                                >
                                                    <option value="">선 택</option>
                                                    {militaryList.map((list, idx) => (
                                                        <option key={idx} value={list.codeValue}>{list.codeName}</option>
                                                    ))}
                                                </select>
                                                {/*<Select
                                                    value={{label: member.militaryName, value: member.military}}
                                                    options={militaryList}
                                                />*/}
                                            </div>
                                            {errors.military && <small role="alert">{errors.military.message}</small> }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>최초 정보습득경로 <span className="rq">*</span></th>
                                        <td colspan="3">
                                            <div className="defaultSel">
                                                <select title="최초 정보습득경로" className="size06" id="mediamaster_media_cd" name="mediamaster_media_cd"
                                                        aria-invalid={isSubmitted ? (errors.accuisitionPath ? "true" : "false") : undefined}
                                                        {...register("accuisitionPath", {
                                                            required: "최초 정보습득경로는 필수 입력입니다."
                                                        })}
                                                >
                                                    <option value="">선택</option>
                                                    {accuisitionPathList.map((list, idx) => (
                                                        <option key={idx} value={list.codeValue}>{list.codeName}</option>
                                                    ))}
                                                </select>
                                                {/*<Select
                                                    value={{label: member.accuisitionPathName, value: member.accuisitionPath}}
                                                    options={accuisitionPathList}
                                                />*/}

                                            </div>
                                            {/*<span className="redInfo">※ 최초 정보습득 경로를 정확히 기재바랍니다.</span>*/}
                                            {errors.accuisitionPath && <small role="alert">{errors.accuisitionPath.message}</small> }
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>관련사항</th>
                                        <td colSpan="3">
                                            <span className="redInfo">※ 자격사항/ 교육이수/ 경력사항/ 공모전 등 기타 추가 강점 자유롭게 기재</span>
                                            <textarea name="add_record" id="add_record" className="txt_area" title="관련사항"
                                                      {...register("bigo", {
                                                          // maxLength: {
                                                          //     value: 500,
                                                          //     message: "500자 이내로 입력바랍니다.",
                                                          // },
                                                      })}
                                            ></textarea>
                                            {errors.bigo && <small role="alert">{errors.bigo.message}</small>}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div></>) || <><div className="titleType10">
                            <span>재직정보 입력사항</span>
                        </div>
                            <div className="viewType01 inputTb2 border marB_50">
                                <table>
                                    <colgroup>
                                        <col id="title" />
                                        <col />
                                    </colgroup>

                                    <tbody>
                                    <tr>
                                        <th>회사명 </th>
                                        <td>
                                            <input className="size01 gray" type="text" name="companyname"  id="companyname"
                                                   {...register("companyname")} />

                                        </td>
                                    </tr>
                                    <tr>
                                        <th>직장전화번호 </th>
                                        <td>
                                            <input className="size01 gray" type="text" name="jiktel"  id="jiktel"
                                                   {...register("jiktel")} />

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>}



                        <div className="btnCenter">

                            <button className="btType01 blue" type="submit" disabled={isSubmitting}>수강신청 수정</button>

                        </div>

                    </form>
                </div>
            </section>
        </main>
    )
}

export default RegistrationEdit;