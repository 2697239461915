import "./trainingDetail.scss";
import DetailThumbnail from "../../components/detailThumbnail/DetailThumbnail";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import BenefitSliderBox from "../../components/benefitSliderBox/BenefitSliderBox";
import QualificationItem from "../../components/qualificationItem/QualificationItem";
import CourseDetailWidget from "../../components/courseDetailWidget/CourseDetailWidget";
import React, {useCallback, useEffect, useRef, useState} from "react";
import ShareModal from "../../components/shareModal/ShareModal";
import {pageContentSelector, lectureDetailSelector} from "../../store/Selector";
import {useRecoilValueLoadable} from "recoil";
import { Parser } from "html-to-react";
import {Link, useParams} from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import OverviewItem from "../../components/overviewItem/OverviewItem";
import ScreeningItem from "../../components/overviewItem/ScreeningItem";


function TrainingDetail () {
  let params = useParams();

  const [contents, setContents] = useState([]);
  const contentsDatas = useRecoilValueLoadable(pageContentSelector("trainingDetail"));

  const lectureData = useRecoilValueLoadable(lectureDetailSelector(params?.lectureId));
  const [lecture, setLecture] = useState([]);
  const [tags, setTags] = useState([]);
  const [lectureDetails, setLectureDetails] = useState([]);
  const [titleKo, setTitleKo] = useState('');

  const [ isOpenShareModal, setIsOpenShareModal ] = useState(false);
  const scrollTopPointRef = useRef(null);

  const requestFetchContents = useCallback(() => {
    if (contentsDatas === null || contentsDatas === undefined) {
      return;
    }
    switch (contentsDatas.state) {
      case 'hasValue':
        const result = contentsDatas.contents;
        const contents = result.contents;
        setContents(contents);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw contentsDatas.contents;
    }
  }, [setContents, contentsDatas]);

  const requestFetchLecture = useCallback(() => {
    if (lectureData === null || lectureData === undefined) {
      return;
    }
    switch (lectureData.state) {
      case 'hasValue':
        const result = lectureData.contents;
        const lectures = result.contents;
        //console.log(lectures);
        setLecture(lectures[0]);
        //lecture.title = checkName(lecture.title);
        setTags(lectures[0].tags);
        setLectureDetails(lectures[0].lectureDetailList);
        break;

      case 'loading':
        return <div>로딩중입니다...</div>;
      case 'hasError':
        throw lectureData.contents;
    }
  }, [setLecture, lectureData]);

  useEffect(() => {
    requestFetchContents();
  }, [requestFetchContents]);

  useEffect(() => {
    requestFetchLecture();
  }, [requestFetchLecture, params?.lectureId]);

  useEffect(() => {
    if (lecture.length !== 0) {
      //console.log(lecture);
      setTitleKo(checkName(lecture.title));
      //setLecture({...lecture, ...titleKo});
    }
  }, [lecture]);


  // slider custom btn
  const CustomNextArrow = (props) => {
    const { onClick } = props;

    return(
      <div className={`detailCustomArrowWrap detailNextArrow`} onClick={onClick}>
        <img src={require("../../assets/icons/ico-right-arrow-black.png")} alt="" />
      </div>
    )
  }
  const CustomPrevArrow = (props) => {
    const { onClick } = props;

    return(
      <div className={`detailCustomArrowWrap detailPrevArrow`} onClick={onClick}>
        <img src={require("../../assets/icons/ico-right-arrow-black.png")} alt="" />
      </div>
    )
  }

  const settings = {
    infinite: true,
    slidesToShow: window.innerWidth > 950 ? 3 : window.innerWidth > 700 ? 2 : 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  // 과목명 병합을 위한 처리
  let subjectsArr = {};
  const rowSpan = lectureDetails.reduce((result, item, key) => {
    if (subjectsArr[item.subject] === undefined) {
      subjectsArr[item.subject] = key;
      result[key] = 1;
    } else {
      const firstIndex = subjectsArr[item.subject];
      if (
          firstIndex === key - 1 ||
          (item.subject === lectureDetails[key - 1].subject && result[key - 1] === 0)
      ) {
        result[firstIndex]++;
        result[key] = 0;
      } else {
        result[key] = 1;
        subjectsArr[item.subject] = key;
      }
    }
    return result;
  }, []);


  const checkName = (name) => {
    //name의 마지막 음절의 유니코드(UTF-16)
    const charCode = name.charCodeAt(name.length - 1);

    //유니코드의 한글 범위 내에서 해당 코드의 받침 확인
    const consonantCode = (charCode - 44032) % 28;

    //console.log('consonantCode : ', consonantCode);

    if(consonantCode === 0){
      //0이면 받침 없음 -> 를
      return `${name}는`;
    }
    //1이상이면 받침 있음 -> 을
    return `${name}은`;
  }


  return (
    <main className="detailSection">
      {contents.map(list => (
        <DetailThumbnail
          key={lecture.id}
          defaultImgUrl={`/download/${list.trainingDetail_header_image}`}
          imgUrl={lecture.backgroundUrl}
          lecture={lecture}

          miniNavProps={
            {
              parentMenu: {
                title: "훈련 과정",
              },
              childMenu: {
                title: lecture.lectureType === '01' ? "취업 훈련" : (lecture.lectureType === '02' ? "직무 훈련" : "원격 훈련"),
                menuId: 1,
              }
            }
          }
        />
      ))}


      <section className="detailSection--contentsWrap container--w100" >
          <div className="scrollTopPoint" ref={scrollTopPointRef} />
        <div className="detailSection--contentsWrap__primarySection container">
          <div className="detailSection--contentsWrap__primarySection--inner" >
            <img src={lecture.detailUrl} alt="" className="lectureImage"/>
            <div className="detailSection--contentsWrap__primarySection--textWrap">
              <div className="detailSection--contentsWrap__primarySection--textWrap__titleWrap">
                <p className="detailSection--contentsWrap__primarySection--textWrap__titleWrap--tags">

                  {tags.map((list, idx) => (
                      <span key={idx}>#{list}&nbsp;</span>
                  ))}

                </p>
                <div className="detailSection--contentsWrap__primarySection--textWrap__titleWrap--titleBox">
                  <div className="detailSection--contentsWrap__primarySection--textWrap__titleWrap--titleBox__left">
                    <h1>{lecture.title}</h1>
                    {(moment().diff(moment(lecture.applyStartDate)) < 0)
                      ? <span>접수예정</span>
                      : (moment().diff(moment(lecture.applyEndDate), "days") > 0)
                        ? <span>신청마감</span>
                            : <span>신청진행중</span>
                    }
                  </div> 
                  <img className="primarySection--shareIcon" onClick={() => setIsOpenShareModal(true)} src={require("../../assets/icons/ico-share.png")} alt=""/>
                </div>
              </div>

              <div className="detailSection--contentsWrap__primarySection--textWrap__paragraphWrap">
                <p className="detailSection--contentsWrap__primarySection--textWrap__paragraphWrap--p">
                  {Parser().parse(lecture.intro1)}
                </p>
                <div className="detailSection--contentsWrap__primarySection--textWrap__paragraphWrap__periodBoxWrap">
                  <div className="detailSection--contentsWrap__primarySection--textWrap__paragraphWrap__periodBoxWrap--periodBox--duration">
                    <h3>신청 기간</h3>
                    <p>{moment(lecture.applyStartDate).format("YYYY.MM.DD")} ~ {moment(lecture.applyEndDate).format("YYYY.MM.DD")}</p>
                  </div>
                  <div className="detailSection--contentsWrap__primarySection--textWrap__paragraphWrap__periodBoxWrap--periodBox--detail">
                    <h3>교육 기간</h3>
                    <p>{moment(lecture.startDate).format("YYYY.MM.DD")} ~ {moment(lecture.endDate).format("YYYY.MM.DD")}</p>
                  </div>
                  <Link to={`/registrationCheck/${lecture.id}`}>
                    <div className="detailSection--contentsWrap__primarySection--textWrap__paragraphWrap__periodBoxWrap--periodBox--application">
                      <h3>접수내역 확인</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
         
          <div className="detailSection--contentsWrap__primarySection--footer">
            <span>학습대상</span>
            <span>{lecture.targetAudience}</span>
          </div>
        </div>

        <div className="detailSection--contentsWrap__infoWrap">
          <div className="detailSection--contentsWrap__infoWrap--eachSection">
            <h1>교육 특장점</h1>
            <p className="infoWrap--subTtitle">{Parser().parse(lecture.intro2)}</p>

            <div className="detailSection--contentsWrap__infoWrap--eachSection--imgWrap">
              <img src={require("../../assets/images/courseDetailImg/featuresSection-img.gif")} alt=""/>
            </div>
          </div>

          <div className="detailSection--contentsWrap__infoWrap--eachSection">
            <h1>모집 개요</h1>
            <p>{Parser().parse(lecture.intro3)}</p>

            <div className="detailSection--contentsWrap__infoWrap--eachSection--overviewWrap">
              {/*<img src={require("../../assets/images/courseDetailImg/recruitment-img.png")} alt=""/>*/}
              <OverviewItem
                img={require("../../assets/images/courseDetailImg/icon/job_fields.png")}
                title={"모집 분야 및 규모"}
                contents={
                  <p>
                    {moment().year()}년 <br />
                    풀스택 개발자 양성과정 <br />
                    교육생 56명
                  </p>
                }
              />
              <OverviewItem
                  img={require("../../assets/images/courseDetailImg/icon/edu_period.png")}
                  title={"교육 기간"}
                  contents={
                    <p>
                      {moment(lecture.startDate).format("YYYY.MM.DD")} ~ {moment(lecture.endDate).format("YYYY.MM.DD")} <br />
                      (1일 {lecture.dayTime}시간, 총
                      {Math.ceil(moment.duration(moment(lecture.endDate).diff(moment(lecture.startDate))).asMonths())}
                      개월/{lecture.totalTime}시간)
                    </p>
                  }
              />
              <OverviewItem
                  img={require("../../assets/images/courseDetailImg/icon/appli_recept.png")}
                  title={"신청 접수"}
                  contents={
                    <p>
                      {moment(lecture.applyStartDate).format("YYYY.MM.DD")} ~ {moment(lecture.applyEndDate).format("YYYY.MM.DD")}
                    </p>
                  }
              />
              <OverviewItem
                  img={require("../../assets/images/courseDetailImg/icon/qualification.png")}
                  title={"지원 자격"}
                  contents={
                    <p>
                      ∙ 아래 명시된 지원 자격에 부합하는 자 <br />
                      ∙ 장애인/보훈대상자는 관련 <br />
                      &nbsp;&nbsp;법령에 따라 우대 <br />
                      <span style={{display: "inline-block", marginLeft: "10px", fontSize: "smaller"}}>
                        ※ 내일배움카드는 신청 완료 후 <br />
                      {moment(lecture.startDate).subtract(1, 'd').format("M/D(ddd)")}까지 필수 수령</span>
                    </p>
                  }
              />
              <OverviewItem
                  img={require("../../assets/images/courseDetailImg/icon/edu_target.png")}
                  title={"교육 대상"}
                  contents={
                    <p>
                      ∙ SW 개발에 관심이 있는 자 <br />
                      ∙ 금융 분야 취업을 희망하는 자 <br />
                      ∙ 디지털∙신기술 분야 관심이 있는 자 <br />

                    </p>
                  }
              />
              <OverviewItem
                  img={require("../../assets/images/courseDetailImg/icon/edu_loc.png")}
                  title={"교육 장소"}
                  contents={
                    <p>
                      서울특별시 마포구 월드컵북로 4길 77, 1층 <br />
                      <span style={{fontSize: "smaller"}}>
                        지하철: 홍대입구역 2번 출구 도보 5분 <br />
                        버 스: 홍대입구역 중앙차로 하차 도보 5분
                      </span>
                    </p>
                  }
              />
            </div>

            <div className="detailSection--contentsWrap__infoWrap--eachSection--tagsWrap">
              {tags.map((list, idx) => (
                <div key={idx} className="detailSection--contentsWrap__infoWrap--eachSection--tagsWrap--tagBox">
                  #{list}
                </div>
              ))}
            </div>
          </div>

          <div className="detailSection--contentsWrap__infoWrap--eachSection">
            <h1>지원 자격</h1>
            {/*<p className="infoWrap--subTtitle">내일배움카드는 신청 완료 후&nbsp;*/}
            {/*  <span>{moment(lecture.startDate).subtract(1, 'd').format("M/D(ddd)")}까지 필수 수령</span>입니다.</p>*/}

            <div className="detailSection--contentsWrap__infoWrap--eachSection--qualificationWrap">
              <QualificationItem 
                title={"학력"} 
                contents={
                  <p>
                    ∙ 정규 4년제 대학(학사 이상) 졸업자 및 <br />
                      &nbsp; 졸업 예정자
                    {/*(`{moment().format('YY')}년 {moment().isBefore('2024-03-01', 'month') ? '2' : '8'}월) */}
                    <br />
                    ∙ 전공 무관 <br />
                    ∙ 프로그램밍 기초 소양 보유자 및 <br />
                    &nbsp; 관련 자격증 소지자 선발 우대 
                  </p>
                }
              />
              <QualificationItem
                title={"연령"} 
                contents={
                  <p>
                    ∙ 국민내일배움카드 발급 기준 외 <br />
                      &nbsp; 제한 없음 <br />
                  </p>
                }
              />
              <QualificationItem 
                title={"취업 여부"} 
                contents={
                  <p>
                    ∙ 현재 미취업자 <br />
                      &nbsp;&nbsp; -고용보험 미가입자 <br />
                      &nbsp;&nbsp; -15시간/주 미만 근로자인 경우 <br />
                      &nbsp;&nbsp;&nbsp;&nbsp; 미취업자로 간주 <br />
                    ∙ 훈련 종료 후 적극적 취업 의사가 있는 자 <br />
                  </p>
                }
              />
              <QualificationItem 
                title={"국민내일배움카드"} 
                contents={
                  <p>
                    ∙ 국민내일배움카드 보유 또는 <br />
                    &nbsp;&nbsp; 발급 가능한 자 <br />
                    - K-Digital Training 과정으로 등록 <br />
                    &nbsp;&nbsp; 이력이 있는 경우  지원 불가 <br />
                    &nbsp;&nbsp; (HRD-NET 등록 기준) <br />
                    - 훈련개시일 1주일 전 미보유 시‌ <br />
                    &nbsp;&nbsp; 선발 취소
                  </p>
                }
              />
              <QualificationItem 
                title={"기타 사항"} 
                contents={
                  <p>
                    ∙ 교육기간 성실히 교육 참여 가능한 자 <br />
                    ∙ 교육기간 중 통학 가능한 자 <br />
                    &nbsp;&nbsp;(숙식/교통비 미제공) <br />
                    ‌∙ 남자의 경우 훈련개시일 기준 <br />
                    &nbsp;&nbsp;병역필 또는 면제자
                  </p>
                }
              />
            </div>
          </div>

          <div className="detailSection--contentsWrap__infoWrap--eachSection">
            <h1>전형 절차</h1>

            <div className="detailSection--contentsWrap__infoWrap--eachSection--screenWrap">
              <ScreeningItem
                img={require("../../assets/images/courseDetailImg/icon/appli_submit.png")}
                title={
                  <h1>
                    클릭하여<br/>
                    <strong>원서 접수</strong>
                  </h1>
                }
                contents={
                  <p>
                    {moment(lecture.applyStartDate).format("YYYY.MM.DD")} <br />
                    ~ <br />
                    {moment(lecture.applyEndDate).format("YYYY.MM.DD")}
                  </p>
                }
                />
              <ScreeningItem
                  img={require("../../assets/images/courseDetailImg/icon/doc_screen.png")}
                  title={
                    <h1>
                      <strong>서류 전형</strong>
                    </h1>
                  }
                  contents={
                    <p>

                      ~ {moment(lecture.documentEndDate).format("YYYY.MM.DD")}
                    </p>
                  }
              />
              <ScreeningItem
                  img={require("../../assets/images/courseDetailImg/icon/person_test.png")}
                  title={
                    <h1>
                      <strong>인성검사</strong>
                    </h1>
                  }
                  contents={
                    <p>개별 안내</p>
                  }
              />
              <ScreeningItem
                  img={require("../../assets/images/courseDetailImg/icon/interview.png")}
                  title={
                    <h1>
                      사전테스트 및<br/>
                      <strong>면접 전형</strong>
                    </h1>
                  }
                  contents={
                    <p>
                      {moment(lecture.testStartDate).format("YYYY.MM.DD")} <br />
                      ~ <br />
                      {moment(lecture.testEndDate).format("YYYY.MM.DD")}
                    </p>
                  }
              />
              <ScreeningItem
                  img={require("../../assets/images/courseDetailImg/icon/final_selection.png")}
                  title={
                    <h1>
                      최종
                      <strong>선발</strong>
                    </h1>
                  }
                  contents={
                    <p>

                      {moment(lecture.finalSelectionDate).format("YYYY.MM.DD")}
                    </p>
                  }
              />

            </div>
          </div>
        </div>

        <div className="detailSection--contentsWrap__infoWrap infoWrap-white">
          <div className="detailSection--contentsWrap__infoWrap--eachSection">
            <h1>커리큘럼</h1>
            <p className="infoWrap--subTtitle">{titleKo}
              &nbsp;<span>{Math.ceil(moment.duration(moment(lecture.endDate).diff(moment(lecture.startDate))).asMonths())}개월,
                &nbsp;{lecture.totalTime}시간</span>&nbsp;커리큘럼으로 운영됩니다.</p>

            <div className="detailSection--contentsWrap__infoWrap--eachSection--imgWrap">
              <p>{Parser().parse(lecture.intro4)}</p>
            </div>
          </div>
      
          <div className="detailSection--contentsWrap__infoWrap--eachSection">
            <h1>세부 커리큘럼</h1>
            <p className="infoWrap--subTtitle">기초부터 실전 프로젝트까지 하나하나 제대로 배우는 것이 중요합니다.</p>

            <div className="detailSection--contentsWrap__infoWrap--eachSection--imgWrap">
              <p>{Parser().parse(lecture.intro5)}</p>
            </div>

            <div className="detailSection--contentsWrap__infoWrap--eachSection">
              {/*<img src={require("../../assets/images/courseDetailImg/curriculum-detail-img.png")} alt=""/>*/}
              <table>
                <tbody>
               {lectureDetails.map((list, idx) => (
                  <tr key={idx}>
                    {rowSpan[idx] > 0  && <th rowSpan={rowSpan[idx]}>{list.subject}</th>}
                    <td>{list.content}</td>
                    {rowSpan[idx] > 0  && <td rowSpan={rowSpan[idx]}>이론 {list.theoryTime}시간<br/>
                        실기 {list.practiceTime}시간
                    </td>}
                  </tr>
              ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="detailSection--contentsWrap__infoWrap">
          <div className="detailSection--contentsWrap__infoWrap--eachSection infoWrap-title-align-left">
            <h1>교육 혜택</h1>
            <p>최상의 교육 효과가 발휘될 수 있도록 훈련생을 위하여 다음의 사항들을 지원합니다.</p>

            <div className="detailSection--contentsWrap__infoWrap--eachSection--sliderWrap">
              <Slider {...settings}>
                <div className="detailSection__sliderInner-box">
                  <BenefitSliderBox 
                    title="협약 기업 취업 연계"
                    iconUrl="benefits-05.png"
                    contents={
                      <p>
                        취업에 도움이 될 수 있도록 실무 중심 프로젝트와 <br />
                        문제해결 중심으로 훈련이 진행되며 <br />
                        <span className={{ color: "#064AFE"}}>- 수료자 대상 신한DS 공개 채용시 서류전형 면제</span> <br />
                        - 잡페어를 통해 관계사 및 파트너사 등 채용 추천
                      </p>
                    }
                  />
                </div>
                <div className="detailSection__sliderInner-box">
                  <BenefitSliderBox 
                    title="훈련비 전액 지원"
                    iconUrl="benefits-01.png"
                    contents={
                      <p>
                        국민내일배움카드 발급자를 대상으로 운영되며, <br />
                        K-디지털 트레이닝 훈련을 최초로 신청하시는 <br />
                        경우 훈련비가 전액 지원됩니다. <br />
                        *1인당 1,700만원 상당 (훈련+교재+취업지원)
                      </p>
                    }
                  />
                </div>
                <div className="detailSection__sliderInner-box">
                  <BenefitSliderBox 
                    title="훈련장려금 지급"
                    iconUrl="benefits-02.png"
                    contents={
                      <p>
                        성실히 훈련에 참여하는 훈련생은 <br />
                        매월 훈련장려금을 지원받아 부담없이 교육훈련에 <br />
                        참여 가능합니다. <br />
                        * 6개월간 월 최대 316,000원 <br />
                        (2022년 기준, 출석률에 따라 변동)
                      </p>
                    }
                  />
                </div>
                <div className="detailSection__sliderInner-box">
                  <BenefitSliderBox 
                    title="우수 훈련생 시상"
                    iconUrl="benefits-03.png"
                    contents={
                      <p>
                        준비된 인재들의 꿈을 지원하기 위해 <br />
                        우수 교육생을 선발하여 다양한 <br />
                        포상(교육지원비) 제도를 운영합니다. <br />
                        * 지급 대상과 규모는 훈련생 모집 정도에 따라 변동 <br />
                      </p>
                    }
                  />
                </div>
                <div className="detailSection__sliderInner-box">
                  <BenefitSliderBox 
                    title="노트북 지원"
                    iconUrl="benefits-04.png"
                    contents={
                      <p>
                        걱정 없이 수업에 참여할 수 있도록 <br />
                        교육기간 동안 <br />
                        최신형 노트북을 제공해드립니다. <br />
                      </p>
                    }
                  />
                </div>
                <div className="detailSection__sliderInner-box">
                  <BenefitSliderBox 
                    title="취업 컨설팅"
                    iconUrl="benefits-06.png"
                    contents={
                      <p>
                        훈련생 역량 강화와 취업 지원을 위해 <br />
                        전문컨설턴트의 훈련생 1:1 개별 상담을 통해 <br />
                        자소서 클리닉, 모의 스피치, 인적성 검사 등을 <br />
                        지원합니다.
                      </p>
                    }
                  />
                </div>
              
              </Slider>
            </div>
          </div>
        </div>

        <div style={{borderBottom: "none"}} className="detailSection--contentsWrap__infoWrap infoWrap-white">
          <div className="detailSection--contentsWrap__infoWrap--eachSection infoWrap-title-align-left">
            <h1>취업 지원 서비스</h1>
            <p>취업대비 실전교육 및 개인별 취업 상담과 컨설팅서비스를 제공합니다.</p>

            <div className="detailSection--contentsWrap__infoWrap--eachSection--imgWrap">
              <img src={require("../../assets/images/courseDetailImg/consulting-img.png")} alt=""/>
            </div>
          </div>
        </div>
      </section>

      {/* widget */}
      <CourseDetailWidget
          applyStartDate={lecture.applyStartDate}
          applyEndDate={lecture.applyEndDate}
          startDate={lecture.startDate}
          lectureId={lecture.id}
      />

      {/* share modal */}
      <ShareModal isOpenShareModal={isOpenShareModal} setIsOpenShareModal={setIsOpenShareModal} lectureId={params?.lectureId}/>

      {/* scrollTop btn */}
      <div 
        className="detailSection--scrollTopBtn"
        onClick={() => scrollTopPointRef.current?.scrollIntoView({ behavior: 'smooth' })}
      >
        <img src={require("../../assets/icons/ico-right-arrow.png")} alt="" />
      </div>
    </main>
    )
}

export default TrainingDetail;